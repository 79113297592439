let SEC = SEC || {};

SEC.brands = (function () {
  let init;

  const activatedTags = [];
  let tagsData;

  let filterBrands;
  let generateTagsList;
  let generateTagSpots;
  let getTagsDataByTagType;
  let getTagIdByClass;
  let getTagSlugById;
  let handleTagClick;

  let $tagsList;
  let $brands;
  let $tags;

  getTagsDataByTagType = (tagTypes) => {
    if (!tagsData || !tagTypes) {
      return [];
    }

    const filteredTags = [];

    for (let i=0, len=tagsData.length; i<len; i++) {
      if (tagTypes.indexOf(tagsData[i]['type']) !== -1) {
        filteredTags.push(tagsData[i]);
      }
    }

    return filteredTags;
  };

  getTagIdByClass = (cls) => {
    const tagPattern = /tag-([-a-z]+)\b/;
    if (cls.indexOf('tag-') === -1) {
      return null;
    }
    const tagSlug = cls.match(tagPattern)[1];
    for (let i=0, len=tagsData.length; i<len; i++) {
      if (tagsData[i]['slug'] == tagSlug) {
        return tagsData[i]['id'];
      }
    }
    return null;
  };

  getTagSlugById = (id) => {
    for (let i=0, len=tagsData.length; i<len; i++) {
      if (tagsData[i]['id'] == id) {
        return tagsData[i]['slug'];
      }
    }
    return null;
  };

  generateTagsList = ($brandsList) => {
    if (!tagsData) {
      return false;
    }

    const tagGroups = [
      {'types': 'p,s', 'header': 'Фильтровать:'},
      {'types': 't', 'header': 'По товарам:'}
    ];

    $tagsList = document.createElement('div');
    addClass($tagsList, 'tags-list');

    for (let i=0, len=tagGroups.length; i<len; i++) {
      const $tagsGroup = document.createElement('ul');
      addClass($tagsGroup, 'tags-group');

      const $tagsGroupHdr = document.createElement('li');
      addClass($tagsGroupHdr, 'tags-group__hdr');
      $tagsGroupHdr.innerText = tagGroups[i]['header'];
      $tagsGroup.append($tagsGroupHdr);

      const filteredTagsData = getTagsDataByTagType(tagGroups[i]['types']);
      for (let j=0, len=filteredTagsData.length; j<len; j++) {
        const tagData = filteredTagsData[j];
        const $tag = document.createElement('li');
        $tag.innerHTML = '<a href="" class="cnt">' + tagData['name'] + '</a>';
        addClass($tag, 'tag tag-' + tagData['slug']);
        $tagsGroup.append($tag);
      }

      $tagsList.append($tagsGroup);
    }

    $brandsList.parentNode.insertBefore($tagsList, $brandsList);

    // вырваниваем заголовки по ширине
    const headers = $tagsList.querySelectorAll('.tags-group__hdr');
    let maxHeaderWidth = 0;

    for (let i=0, len=headers.length; i<len; i++) {
      const headerWidth = headers[i].offsetWidth;
      if (headerWidth > maxHeaderWidth) {
        maxHeaderWidth = headerWidth;
      }
    }

    if (maxHeaderWidth) {
      addCssStyleToPage('.tags-group__hdr {min-width:' + maxHeaderWidth + 'px;}');
    }

    return true;
  };

  generateTagSpots = () => {
    if (!$brands) {
      return false;
    }

    let tagSlugs = [];
    let tagIds = [];
    for (let i=0, len=tagsData.length; i<len; i++) {
      const tagData = tagsData[i];
      if (tagData['type'] !== 's') {
        continue;
      }

      tagSlugs.push(tagData['slug']);
      tagIds.push('' + tagData['id']); // int --> str
    }

    let tagSpots = [];
    for (let i=0, len=tagIds.length; i<len; i++) {
      let span = document.createElement('span');
      addClass(span, 'tagspot tagspot--empty');
      tagSpots.push(span);
    }

    for (let i=0, len=$brands.length; i<len; i++) {
      let brandTagIds;
      const $brand = $brands[i];

      const tagSpotsEl = document.createElement('div');
      addClass(tagSpotsEl, 'tagspots');
      $brand.insertBefore(tagSpotsEl, $brand.firstChild);

      if ($brand.dataset.tags) {
        brandTagIds = $brand.dataset.tags.split(',');
      }

      for (let j=0, len=tagSpots.length; j<len; j++) {
        let clonedTagSpot = tagSpots[j].cloneNode();
        if (brandTagIds && brandTagIds.includes(tagIds[j])) {
          removeClass(clonedTagSpot, 'tagspot--empty');
          addClass(clonedTagSpot, 'tagspot-' + tagSlugs[j]);
        }
        tagSpotsEl.append(clonedTagSpot);
      }
    }

  };

  filterBrands = () => {
     for (let i=0, len=$brands.length; i<len; i++) {
       const brandTags = $brands[i].dataset.tags;
       let isTagsIntersect;

       if (brandTags) {
         isTagsIntersect = brandTags.split(',').map(Number).some(r=> activatedTags.includes(r));
       }

       if (activatedTags.length > 0 && (!brandTags || !isTagsIntersect)) {
         addClass($brands[i], 'hidden');
       } else {
         removeClass($brands[i], 'hidden');
       }
     }
  };

  handleTagClick = ($a) => {
    const $tag = $a.parentNode;
    const tagId = getTagIdByClass($tag.className);
    if (hasClass($tag, 'tag--activated')) {
      const index = activatedTags.indexOf(tagId);
      if (index !== -1) {
        activatedTags.splice(index, 1);
      }
      removeClass($tag, 'tag--activated');
    } else {
      activatedTags.push(tagId);
      addClass($tag, 'tag--activated');
      SEC.clickyLog('#brands__tagClick::' + getTagSlugById(tagId));
    }
    if (activatedTags.length > 0) {
      addClass($tagsList, 'tags-list--activated');
    } else {
      removeClass($tagsList, 'tags-list--activated');
    }

    filterBrands();
  };

  init = () => {
    if (SEC.page.indexOf('brands') === -1) {
      return false;
    }

    let $brandsList = document.querySelector('.js-brands');
    if (!$brandsList) {
      return false;
    }
    $brands = $brandsList.querySelectorAll('.brand-item');

    if ($brandsList.dataset.tagsInfo) {
      tagsData = JSON.parse($brandsList.dataset.tagsInfo);
      generateTagsList($brandsList);
    }

    if (!$tagsList) {
      return false;
    }

    generateTagSpots();

    $tagsList.addEventListener('click', (e) => {
      const handlers = [
        ['.tag a', handleTagClick]
      ];
      dispatchEvents(e, handlers);
    });
  };

  return {
    init
  };
})();

document.addEventListener('DOMContentLoaded', function () {
  SEC.brands.init();
});
