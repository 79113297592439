function hasClass(el, className) {
  if (!el) {return false;}
  return el.classList ? el.classList.contains(className) : new RegExp('\\b' + className + '\\b').test(el.className);
}

function addClass(el, className) {
  if (!el) {return false;}
  if (className.indexOf(' ') !== -1) {
    let classes = className.split(' ');
    for (var i=0, len=classes.length; i<len; ++i) {
      addClass(el, classes[i]);
    }
  } else {
    if (el instanceof NodeList || el instanceof Array) {
      if (el.length === 0) {
        return false;
      }
      for (var i=0, len=el.length; i<len; ++i) {
        addClass(el[i], className);
      }
    }
    if (el.classList) el.classList.add(className);
    else if (!hasClass(el, className)) el.className += ' ' + className;
  }
}

function removeClass(el, className) {
  if (className.indexOf(' ') !== -1) {
    let classes = className.split(' ');
    for (var i=0, len=classes.length; i<len; ++i) {
      removeClass(el, classes[i]);
    }
  } else {
    if (el instanceof NodeList || el instanceof Array) {
      if (el.length === 0) {
        return false;
      }
      for (var i = 0, len = el.length; i < len; ++i) {
        removeClass(el[i], className);
      }
    }
    if (!hasClass(el, className)) {
      return false;
    }
    if (el.classList) el.classList.remove(className);
    else el.className = el.className.replace(new RegExp('\\b' + className + '\\b', 'g'), '');
  }
}

function setAttributes(el, attrs) {
  for (let key in attrs) {
    el.setAttribute(key, attrs[key]);
  }
}

function removeAttributes(el, attrs) {
  for (let i=0; i<attrs.length; i++) {
    el.removeAttribute(attrs[i]);
  }
}

function wrap(el, wrapper) {
    el.parentNode.insertBefore(wrapper, el);
    wrapper.appendChild(el);
}

if (window.Element && !Element.prototype.closest) {
  Element.prototype.closest =
  function(s) {
    var matches = (this.document || this.ownerDocument).querySelectorAll(s),
      i,
      el = this;
    do {
      i = matches.length;
      while (--i >= 0 && matches.item(i) !== el) {};
    } while ((i < 0) && (el = el.parentElement));
    return el;
  };
}

if (!Element.prototype.matches) {
  Element.prototype.matches = Element.prototype.msMatchesSelector || Element.prototype.webkitMatchesSelector;
}

function dispatchEvents(e, handlers) {
  if (!e.target || !handlers) {
    return false;
  }

  e.preventDefault();
  const $el = e.target;
  for (let i=0, len=handlers.length; i<len; i++) {
    if ($el.matches(handlers[i][0])) {
      handlers[i][1]($el);
      break;
    }
  }
}

function aniFadeTo($el, fadeTo, callback, options) {
  let o = {
    targets: $el,
    opacity: fadeTo,
    duration: 500,
    easing: 'linear'
  };

  if (options) {
    Object.assign(o, options);
  }

  let ani = anime(o);

  if (callback) {
    ani.finished.then(callback);
  }
}

function aniFadeOut($el, callback, options) {
  aniFadeTo($el, 0, callback, options);
}

function aniFadeIn($el, callback, options) {
  aniFadeTo($el, 1, callback, options);
}

function aniFade($el, content) {
  aniFadeOut($el, function() {
    $el.textContent = content;
    aniFadeIn($el);
  });
}

(function() {
  if (typeof window.CustomEvent === "function") return false;
  function CustomEvent (event, params) {
    params = params || {bubbles: false, cancelable: false, detail: undefined};
    var evt = document.createEvent('CustomEvent');
    evt.initCustomEvent(event, params.bubbles, params.cancelable, params.detail);
    return evt;
   }
  CustomEvent.prototype = window.Event.prototype;
  window.CustomEvent = CustomEvent;
})();

function addCssStyleToPage(css) {
    const styleEl = document.createElement('style');
    styleEl.setAttribute('type', 'text/css');
    if ('textContent' in styleEl) {
      styleEl.textContent = css;
    } else {
      styleEl.styleSheet.cssText = css;
    }
    document.getElementsByTagName('head')[0].appendChild(styleEl);
}

function isTouchDevice() {
  const prefixes = ' -webkit- -moz- -o- -ms- '.split(' ');
  const mq = function(query) {
    return window.matchMedia(query).matches;
  };

  if (('ontouchstart' in window) || window.DocumentTouch && document instanceof DocumentTouch) {
    return true;
  }

  // include the 'heartz' as a way to have a non matching MQ to help terminate the join
  // https://git.io/vznFH
  const query = ['(', prefixes.join('touch-enabled),('), 'heartz', ')'].join('');
  return mq(query);
}

function isVisible(el) {
  const rect = el.getBoundingClientRect();
  const elemTop = rect.top;
  const elemBottom = rect.bottom;

  const scrollTop = window.pageYOffset || document.documentElement.scrollTop;

  return elemBottom - 20 <= document.documentElement.clientHeight;
}