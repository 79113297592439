let SEC = SEC || {};

SEC.citySelect = (function () {
    const BASE_CSS_CLASS = 'dropdown-search';
    const DROPDOWN_SIZE = 8;
    const DEFAULT_TEXT = 'Город доставки';
    const NO_CITY_MSG = 'Такого населенного пункта нет в нашей системе 🤷‍♂️ Мы сможем отправить заказ Почтой России в любой населенный пункт Росии, Беларуси, Казахстана.';
    const IMPORTANT_CITIES = [251, 2] // Москва, СПб

    let uContainer;
    let uDropdown;
    let uSearchInput;
    let uText;
    let uHidden;
    let selectedCityId;
    let selectedCityName;
    let citySelectCallback;
    let _windowBlur;

    const updateSelectedCity = () => {
        // console.log('updateSelectedCity');
        if (!selectedCityId) {
            return;
        }
        uHidden.first().value = selectedCityId;
        updateText(selectedCityName);
        if (citySelectCallback) {
            citySelectCallback(selectedCityId);
        }
    };

    const updateText = (text) => {
        // console.log('updateText');
        uText.removeClass('--default').text(text);
    };

    const resetText = () => {
        uText.text(DEFAULT_TEXT).addClass('--default');
    };

    const clearSelectedItem = () => {
        uDropdown.find('.--selected').removeClass('--selected');
    };

    const makeItemSelected = (uItem) => {
        clearSelectedItem();
        uItem.addClass('--selected');
        updateText(uItem.text());
    };

    const resetDropdown = () => {
        clearSelectedItem();
        uDropdown.find('.--hidden').removeClass('--hidden');
    };

    const resetSearchInput = () => {
        uSearchInput.first().value = '';
    };

    const getSelectedItem = () => {
        return uDropdown.find('li.--selected');
    };


    const getVisibleItems = () => {
        return uDropdown.find('li:not(.--hidden)');
    };

    const getFirstVisibleItem = () => {
        return getVisibleItems().first();
    }

    const getLastVisibleItem = () => {
        return getVisibleItems().last();
    }

    const activateItem = (uItem) => {
        // console.log('activateItem');
        closeDropdown();
        if (!uItem) {
            uItem = getSelectedItem();
            if (!uItem.first()) {
                return;
            }
        }
        resetDropdown();
        resetSearchInput();
        makeItemSelected(uItem);
        uContainer.removeClass('--filtered');
        selectedCityId = uItem.data('value');
        selectedCityName = uItem.text();
        updateSelectedCity();
    };

    const scrollDropdownToSelected = (uPreviousSelectedItem) => {
        const $el = getSelectedItem().first()
        if (!$el) {
            return;
        }
        const $dropdown = uDropdown.first();
        const offsetTop = $el.offsetTop;
        if (uPreviousSelectedItem) {
            // 'page-next' and 'page-previous' cases
            $dropdown.scrollTop += offsetTop - uPreviousSelectedItem.first().offsetTop;
        } else {
            const offsetBottom = offsetTop + $el.offsetHeight;
            if (offsetTop < $dropdown.scrollTop) {
                $dropdown.scrollTop = offsetTop;
            } else if (offsetBottom > $dropdown.scrollTop + $dropdown.offsetHeight) {
                $dropdown.scrollTop = offsetTop - $dropdown.offsetHeight + $el.offsetHeight;
            }
        }
    };

    const openDropdown = () => {
        uContainer.addClass('--open');
        adjustDropdownHeight();
        scrollDropdownToSelected();
    };

    const closeDropdown = () => {
        uContainer.removeClass('--open');
    };

    const checkIsDropdownOpen = () => {
        return uContainer.hasClass('--open');
    };

    const filterItems = (val) => {
        // return first suitable item text
        val = val.toLowerCase();
        let firstSuitable;
        const suitableIds = [];
        uDropdown.find('li').each((node, i) => {
            const cityId = node.dataset.value;
            const nodeText = node.innerText.toLowerCase();
            if (suitableIds.indexOf(cityId) == -1 && nodeText.startsWith(val)) {
                suitableIds.push(cityId);
                u(node).removeClass('--hidden');
                if (!firstSuitable) {
                    makeItemSelected(u(node));
                    firstSuitable = node.innerText;
                }
            } else {
                u(node).addClass('--hidden');
            }
        });
        return firstSuitable;
    };

    const showOtherCityItem = () => {
        const msg = u(`<div class="no-city-msg">${NO_CITY_MSG}</div>`);
        uContainer.parent().append(msg);
    };

    const handleSearchInput = () => {
        if (!checkIsDropdownOpen()) {
            openDropdown();
        }
        const val = uSearchInput.first().value;
        if (val !== '') {
            uContainer.addClass('--filtered');
            const firstSuitableValue = filterItems(val);
            if (firstSuitableValue) {
                uDropdown.removeClass('--empty');
                if (val !== firstSuitableValue.substring(0, val.length)) {
                    uSearchInput.first().value = firstSuitableValue.substring(0, val.length);
                }
            } else {
                uDropdown.addClass('--empty');
                if (citySelectCallback) {
                    updateText('');
                    citySelectCallback(null);
                }
            }
        } else {
            uContainer.removeClass('--filtered');
        }
    };

    const selectItem = (direction) => {
        if (!checkIsDropdownOpen()) {
            return;
        }
        let $el;
        if (direction === 'first') {
            $el = getFirstVisibleItem();
        } else if (direction === 'last') {
            $el = getLastVisibleItem();
        } else {
            // direction is 'next' or 'previous' or 'next-page' or 'previous-page'
            const uSelectedItem = getSelectedItem();
            $el = uSelectedItem.first();
            let counter = 0;
            while (true) {
                if (direction === 'next' || direction === 'next-page') {
                    if ($el) {
                        $el = $el.nextSibling;
                    } else {
                        $el = getFirstVisibleItem();
                    }
                } else if (direction === 'previous' || direction === 'previous-page') {
                    $el = $el.previousSibling;
                }
                if (!$el) {
                    if (direction === 'next-page') {
                        $el = getLastVisibleItem();
                    } else if (direction === 'previous-page') {
                        $el = getFirstVisibleItem();
                    }
                    break;
                }
                if ($el.nodeType === 3) {
                    continue; // text node
                }
                if (u($el).is('li:not(.--hidden)')) {
                    if (direction === 'next' || direction === 'previous' || counter >= DROPDOWN_SIZE - 1) {
                        break;
                    } else {
                        counter++;
                    }
                }
            }
        }
        if ($el) {
            let uPreviousSelectedItem;
            if (direction === 'next-page' || direction === 'previous-page') {
                uPreviousSelectedItem = getSelectedItem();
            }
            makeItemSelected(u($el));
            scrollDropdownToSelected(uPreviousSelectedItem);
        }
    };

    const adjustDropdownHeight = () => {
        const itemHeight = uDropdown.find('li').first().offsetHeight;
        uDropdown.first().style.maxHeight = itemHeight * DROPDOWN_SIZE + 'px';
    };

    const getCityItemHTML = (cityVal, cityName) => {
        return `<li tabindex="-1" data-value="${cityVal}">${cityName}</li>`;
    };

    const createForm = ($el, citiesJSON) => {
        if (!citiesJSON) {
            return;
        }
        uDropdown = u(`<ul class="${BASE_CSS_CLASS + '__dropdown'}">`);
        uSearchInput = u('<input class="search" autocomplete="off">');
        uText = u(`<div class="${BASE_CSS_CLASS + '__text'} --default">${DEFAULT_TEXT}</div>`);
        uHidden = u('<input type="hidden" name="city" value="">');
        const uIcon = u(`<i class="${BASE_CSS_CLASS + '__icon'}">`);
        uContainer =
            u(`<div class=${BASE_CSS_CLASS}>`)
            .append(uSearchInput)
            .append(uHidden)
            .append(uText)
            .append(uDropdown)
            .append(uIcon)
            ;
        let ddHTML = '';
        const importantCitiesHTML = [];
        for (let i=0, len=citiesJSON.length; i<len; i++) {
            const cityData = citiesJSON[i];
            const cityItemHTML = getCityItemHTML(cityData['id'], cityData['name']);
            const idx = IMPORTANT_CITIES.indexOf(cityData['id']);
            if (idx !== -1) {
                importantCitiesHTML[idx] = cityItemHTML;
            }
            ddHTML += cityItemHTML;
        }
        ddHTML = importantCitiesHTML.join('') + ddHTML + getCityItemHTML('-1', 'Другой населенный пункт');
        uDropdown
            .on('click', 'li', function(e) {
                const uItem = u(e.currentTarget);
                uSearchInput.first().focus();
                activateItem(uItem);
            })
            .html(ddHTML)
            ;
        u($el).append(uContainer);
        uSearchInput
            .on('focus', node => {
                if (_windowBlur) {
                    _windowBlur = null;
                } else {
                    openDropdown();
                }
            })
            .on('blur', event => {
                if (document.activeElement === event.currentTarget) {
                    _windowBlur = true;
                }
                if (uSearchInput.first().value !== '' && !uDropdown.first().contains(event.relatedTarget)) {
                    activateItem();
                }
            })
            .on('input', () => {
                handleSearchInput();
            })
            .on('keydown', e => {
                if (e.key === 'Enter' || e.key === 'Escape') {
                    e.preventDefault();
                    activateItem();
                } else if (e.key === 'Tab') {
                    if (checkIsDropdownOpen()) {
                        activateItem();
                    }
                    uSearchInput.first().blur();
                } else if (e.key === 'ArrowDown') {
                    if (checkIsDropdownOpen()) {
                        selectItem('next');
                    } else {
                        openDropdown();
                    }
                } else if (e.key === 'ArrowUp') {
                    selectItem('previous');
                } else if (e.key === 'Home') {
                    selectItem('first');
                } else if (e.key === 'End') {
                    selectItem('last');
                } else if (e.key === 'PageDown') {
                    e.preventDefault();
                    selectItem('next-page');
                } else if (e.key === 'PageUp') {
                    e.preventDefault();
                    selectItem('previous-page');
                }
            })
            .on('click', e => {
                if (!checkIsDropdownOpen()) {
                    openDropdown();
                }
            })
            ;
        uIcon.on('click', e => {
            if (checkIsDropdownOpen()) {
                uSearchInput.first().blur();
            } else {
                uSearchInput.first().focus();
            }
        });
    };

    const activate = (callback) => {
        uSearchInput.first().focus();
        if (callback) {
            citySelectCallback = callback;
        }
    };

    const reset = () => {
        resetDropdown();
        resetSearchInput();
        resetText();
    };

    const init = () => {
    };

    return {
        init,
        activate,
        createForm,
        reset
    };
})();
