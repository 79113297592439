let SEC = SEC || {};

SEC.category = (function () {

  let $$products;

  let init;
  let initOptionSelectors;
  let foldProductListBlocks;
  let moveDiscountBadges;
  let activateFoldedSummaries;
  let doAddToCart;
  let bindCartButtonClicks;
  let equalizeProductBlocks;
  let processDescription;
  let processExtClouds;
  let changeProductImage;
  let productsListGate;

  const viewedProductsBatch = [];
  const VIEWED_PRODUCTS_BATCH_SIZE = 10;
  let checkViewedProductsBatch;
  let transferViewedProductsBatch;
  let transferViewedProductsBatchTimer;
  let viewedProductsCounter = 0;

  let handleGaProductClick;
  let getCurrentCategory;
  let getProductBrand;
  let getProductCode;
  let getProductIndexOnPage;
  let getProductPrice;
  let getProductSlug;
  let getProductVariant;
  let createProductData;
  let createGaCategoryLabelFromPath;
  let markVisibleProducts;
  let prepareGaVisibleProducts;
  let is_gaProductClickTracked;

  let isBrandPage;
  let isExtView;

  createGaCategoryLabelFromPath = (path) => {
    if (path.indexOf('/categories/') !== -1) {
      const categorySlugs = path.split('/categories/')[1];
      return 'c:' + categorySlugs.slice(0, -1);
    }

    if (path.indexOf('/brands/') !== -1) {
      const brandSlugs = path.split('/brands/')[1];
      return 'b:' + brandSlugs.slice(0, -1);
    }

    if (path === '/sale/' || path === '/whats-new/' || path === '/search/' || path === '/priceoffs/') {
      return path.replace(/\//g, '');
    }

    if (path === '/') {
      return 'index';
    }

    return '';
  };

  getCurrentCategory = () => {
    const path = window.location.pathname;
    return createGaCategoryLabelFromPath(path);
  };

  getProductBrand = ($product) => {
    if (!$product || !$product.dataset.brand) {
      return null;
    }

    return $product.dataset.brand;
  };

  getProductCode = ($product) => {
    const $code = $product.querySelector('.c-product__code');
    if ($code) {
      return $code.innerText.split(' ')[1];
    }
    return null;
  };

  getProductIndexOnPage = ($product) => {
    if (!$$products) {
      return null;
    }
    for (let i=0, len=$$products.length; i<len; i++) {
      if ($$products[i] === $product) {
        return i+1;
      }
    }
    return null;
  };

  getProductPrice = ($product) => {
    const $price = $product.querySelector('.js-product-price');
    if (!$price) {
      return null;
    }
    const $amount = $price.querySelector('.product-price__amount');
    if (!$amount) {
      return null;
    }
    return $amount.innerText;
  };

  getProductSlug = ($product) => {
    const $pTitleLink = $product.querySelector('.js-product-title a');
    if (!$pTitleLink) {
      return null;
    }
    return $pTitleLink.getAttribute('href').split('/').reverse()[1];
  };

  getProductVariant = ($product) => {
    const $option = $product.querySelector('.c-product__option');
    if ($option) {
      return $option.innerText;
    }
    return null;
  };

  createProductData = ($product) => {
    const productData = {
      'id': getProductCode($product),
      'name': getProductSlug($product)
    };

    const brand = getProductBrand($product);
    if (brand) {
      productData['brand'] = brand;
    }

    const position = getProductIndexOnPage($product);
    if (position) {
      productData['position'] = position;
    }

    const price = getProductPrice($product);
    if (price) {
      productData['price'] = price;
    }

    const variant = getProductVariant($product);
    if (variant) {
      productData['variant'] = variant;
    }

    return productData;
  };

  handleGaProductClick = ($product, href) => {
    const productData = createProductData($product);

    window.dataLayer.push({
      'ecommerce': {
        'click': {
          'actionField': {'list': getCurrentCategory()},
          'products': [
            productData
          ]
        }
      },
      'event': 'gtm-ee-event',
      'gtm-ee-event-category': 'Enhanced Ecommerce',
      'gtm-ee-event-action': 'Product Clicks',
      'gtm-ee-event-non-interaction': 'False',

      'eventCallback': () => {
        is_gaProductClickTracked = true;
        window.location.assign(href);
      }
    });
  };

  transferViewedProductsBatch = () => {
    const productsData = [];
    const batchLength = Math.min(VIEWED_PRODUCTS_BATCH_SIZE, viewedProductsBatch.length);
    if (batchLength === 0) {
      return false;
    }

    const productList = getCurrentCategory();
    let i = 0;
    while (i < batchLength) {
      const id = viewedProductsBatch.shift();
      let $product;
      for (let j=0, len=$$products.length; j<len; j++) {
        if ($$products[j].dataset.vid === id) {
          $product = $$products[j];
          break;
        }
      }
      if ($product) {
        const productData = createProductData($product);
        productData['list'] = productList;
        productsData.push(productData);
      }
      i++;
    }

    window.dataLayer.push({
      'ecommerce': {
        'impressions': productsData
      },
      'event': 'gtm-ee-event',
      'gtm-ee-event-category': 'Enhanced Ecommerce',
      'gtm-ee-event-action': 'Product Impressions',
      'gtm-ee-event-non-interaction': 'True'
    });
  };

  checkViewedProductsBatch = () => {
    if (viewedProductsBatch.length < VIEWED_PRODUCTS_BATCH_SIZE) {
      return false;
    }

    transferViewedProductsBatch();
  };

  markVisibleProducts = () => {
    if (!$$products || viewedProductsCounter >= $$products.length) {
      window.removeEventListener('scroll', markVisibleProducts);
      window.removeEventListener('resize', markVisibleProducts);
      transferViewedProductsBatch();
      return false;
    }

    for (let i=0, len=$$products.length; i<len; i++) {
      if (hasClass($$products[i], 'c-product--viewed')) {
        continue;
      }

      if (isVisible($$products[i])) {
        if ($$products[i].dataset.vid) {
          viewedProductsBatch.push($$products[i].dataset.vid);
          viewedProductsCounter++;
        }
        addClass($$products[i], 'c-product--viewed');
      }
    }

    checkViewedProductsBatch();
  };

  prepareGaVisibleProducts = () => {
    markVisibleProducts();
    window.addEventListener('scroll', markVisibleProducts);
    window.addEventListener('resize', markVisibleProducts);
    window.addEventListener('beforeunload', transferViewedProductsBatch);
    transferViewedProductsBatchTimer = setInterval(transferViewedProductsBatch, 60000);
  };

  foldProductListBlocks = () => {
    SEC.foldContent('.product-list-block');
  };

  changeProductImage = ($product, imageUrl) => {
    if (!$product || !imageUrl) {
      return;
    }
    const $image = $product.querySelector('.c-product__image img');
    if (!$image) {
      return;
    }
    const prefix = '/static/p/175/';
    const setImgAttribute = (is2x, imageUrl, cleanSrcSet = true) => {
      const srcAttr = is2x ? 'srcset' : 'src';
      const newUrl = prefix + imageUrl;
      if ($image.getAttribute(srcAttr) !== newUrl) {
        $image.setAttribute(srcAttr, newUrl);
      }
      if (is2x) {
        setImgAttribute(false, imageUrl.replace('@2x', ''), false);
      } else if (cleanSrcSet) {
        $image.removeAttribute('srcset');
      }
    };
    const is2x = imageUrl.indexOf('@2x') !== -1;
    setImgAttribute(is2x, imageUrl);
  };

  initOptionSelectors = () => {
    if (!$$products) {
      return;
    }

    const updateActiveVariant = ($product, variantId) => {
      if (!$product || !variantId) {
        return;
      }
      const $variantEl = $product.querySelector('.c-product__cart-btn button');
      if (!$variantEl) {
        return;
      }
      $variantEl.value = variantId;
    }

    const showOptionPrice = ($option, isInstant, $product) => {
      const price = $option.dataset.price;
      const basePrice = $option.dataset.basePrice || null
      if (!$option || !price) {
        return;
      }
      if (!$product) {
        $product = $option.closest('.c-product');
        if (!$product) {
          return;
        }
      }
      const $price = $product.querySelector('.js-product-price');
      if (!$price) {
        return;
      }
      const $amount = $price.querySelector('.price');
      if (!$amount) {
        return;
      }
      const $priceFrom = $price.querySelector('.price__from');
      const $basePrice = $price.querySelector('.base-price');
      if (isInstant) {
        $amount.innerText = price;
        if ($basePrice && basePrice) {
          $basePrice.innerText = basePrice;
        }
      } else if ($priceFrom || $amount.innerText !== price) {
        // $price.parentNode.style.overflow = 'hidden';
        const aniPriceOut = anime({
          targets: $price,
          marginBottom: -40,
          opacity: 0,
          duration: 100,
          easing: 'linear'
        });
        aniPriceOut.complete = () => {
          $amount.innerText = price;
          if (basePrice) {
            $basePrice.innerText = basePrice;
          }
          anime({
            targets: $price,
            marginBottom: 0,
            opacity: 1,
            duration: 100,
            easing: 'linear'
          });
        }
      }
    }

    const activateOption = ($option, isInstant) => {
      if (!$option || hasClass($option, 'is-active')) {
        return;
      }
      const $product = $option.closest('.c-product');
      if ($option.tagName.toLowerCase() === 'option' && 
          $option.closest('select').selectedIndex === 0) {
        removeClass($product, 'has-active-option');
      } else {
        addClass($product, 'has-active-option');
      }
      const $$activeOptions = $option.parentNode.querySelectorAll('.item--active');
      if ($$activeOptions) {
        removeClass($$activeOptions, 'item--active');
      }
      addClass($option, 'item--active');
      if ($option.dataset.price) {
        showOptionPrice($option, isInstant, $product);
      }
      if ($option.dataset.id) {
        updateActiveVariant($product, $option.dataset.id);
      }
      if ($option.dataset.image) {
        changeProductImage($product, $option.dataset.image);
      }
    };

    const optionClickHandler = (e) => {
      const $option = e.target;
      activateOption($option);
    };

    const bindOptions = ($product) => {
      const $options = $product.querySelector('.c-product__options');
      if (!$options) {
        return;
      }
      const $select = $options.querySelector('select');
      if ($select) {
        const $button = $product.querySelector('.c-product__cart-btn button');
        const $firstOption = $select.querySelector('option');
        const $price = $product.querySelector('.product-price__amount');
        if ($firstOption && $price) {
          $firstOption.dataset.price = $price.innerText;
        }
        const $image = $product.querySelector('.c-product__image img');
        if ($firstOption && $image) {
          const src = $image.getAttribute('srcset') || $image.getAttribute('src');
          $firstOption.dataset.image = src.split('/').pop();
        }
        $select.addEventListener('change', () => {
          activateOption($select.options[$select.selectedIndex]);
          if ($select.selectedIndex === 0) {
            SEC.freezeButton($button);
          } else {
            if (hasClass($select, 'is-awaiting-choose')) {
              removeClass($select, 'is-awaiting-choose');
              $select.blur();
              doAddToCart($button);
            } else {
              SEC.unfreezeButton($button);
            }
          }
        });                
      } else {
        const $$options = $options.querySelectorAll('.item');
        for (let i=0, len=$$options.length; i<len; i++) {
          $$options[i].addEventListener('click', optionClickHandler);
        }
      }
    };

    for (let i=0, len=$$products.length; i<len; i++) {
      const $product = $$products[i];
      bindOptions($product);
    }

    // equalizeProductBlocks(['.c-product__summary', '.c-product__options']);
  };

  activateFoldedSummaries = () => {
    const $$foldedSummaries = document.querySelectorAll('.js-product-summary.is-folded');
    if (!$$foldedSummaries) {
      return;
    }

    const cloneSummary = ($summary) => {
      const $clone = $summary.cloneNode(true);
      $clone.setAttribute('style', 'width:' + ($summary.offsetWidth + 20) + 'px');
      addClass($clone, 'c-product__summary-clone');
      removeClass($clone, 'is-folded');
      const sumRect = $summary.getBoundingClientRect();
      const infRect = $summary.parentNode.getBoundingClientRect();
      $clone.style.top = (sumRect.top - infRect.top - 10) + 'px';
      $summary.parentNode.appendChild($clone);
      $clone.addEventListener('mouseleave', () => {
        $clone.remove();
      });
      return $clone;
    };

    for (let i=0, len=$$foldedSummaries.length; i<len; i++) {
      const $summary = $$foldedSummaries[i];
      hoverintent($summary,
        () => {
          cloneSummary($summary);
        },
        () => {}
      );
    }
  };

  doAddToCart = ($button, $form) => {
    if (!$button || !$button.value) {
      return false;
    }

    if (typeof $form === 'undefined') {
      $form = $button.closest('form');
      if (!$form) {
        return false;
      }
    }

    addClass($button, 'btn--in-process');

    const formData = new FormData();
    formData.set('variant', $button.value);
    axios
      .post($form.getAttribute('action'), formData)
      .then((response) => {
        if (response.data['cart_message']) {
          SEC.updateCartMessage(response.data['cart_message']);
          if (response.status === 200) {
            removeClass($button, 'btn--in-process');
            SEC.unfreezeButton($button);
            const $actions = $button.closest('.c-product__shop');
            if ($actions) {
              const $actionsClone = $actions.cloneNode();
              addClass($actionsClone, 'hidden c-product__cart-link');
              $actionsClone.innerHTML = '<a href="/cart/">Перейти в корзину</a>';
              $actions.parentNode.insertBefore($actionsClone, $actions);
              const buttonWidth = $button.offsetWidth;
              const scaleTimeline = anime.timeline({
                targets: $button,
                duration: 150,
                easing: 'linear'
              })
              .add({
                scale: 1.3,
                opacity: .25
              })
              .add({
                scale: 1,
                opacity: 1
              })
              .add({
                opacity: 0,
                complete: () => {
                  $button.innerText = 'Добавлено';
                  addClass($button, 'btn--processed');
                  $button.style.width = buttonWidth + 'px';
                }
              })
              .add({
                delay: 50,
                opacity: 1
              })
              .add({
                delay: 1500,
                targets: $actions,
                opacity: 0,
                duration: 300,
                complete: () => {
                  addClass($actions, 'hidden');
                  removeClass($actionsClone, 'hidden');
                  removeClass($button, 'btn--processed');
                }
              })
              .add({
                delay: 150,
                targets: $actionsClone,
                opacity: [0, 1],
                duration: 300,
              })
              .add({
                delay: 10000,
                targets: $actionsClone,
                opacity: [1, 0],
                duration: 300,
                complete: () => {
                  $actionsClone.remove();
                }
              })
              .add({
                targets: $actions,
                opacity: [0, 1],
                duration: 300,
                begin: () => {
                  $button.innerText = 'В корзину';
                  $button.removeAttribute('style');
                  removeClass($actions, 'hidden');
                }
              })
            }
          }
        }
      });
  };

  bindCartButtonClicks = () => {
    const $$cartButtons = document.querySelectorAll('.c-product__cart-btn button');
    if (!$$cartButtons) {
      return;
    }

    for (let i=0, len=$$cartButtons.length; i<len; i++) {
      const $button = $$cartButtons[i];
      $button.addEventListener('click', (e) => {
        e.preventDefault();
        const $form = $button.closest('form');
        if (!$form) {
          return true;
        }
        $button.blur();
        SEC.freezeButton($button);
        const $product = $button.closest('.c-product');
        if ($product) {
          const logEventName = '#addToCartClick::' + getProductSlug($product);
          SEC.clickyLog(logEventName);
        }

        const $select = $product.querySelector('.c-product__options select');
        if ($select && $select.selectedIndex === 0) {
          addClass($select, 'is-awaiting-choose');
          anime({
            targets: $select,
            scale: [1, 1.25, 1],
            duration: 400,
            easing: 'easeOutCubic',
            complete: () => {
              $select.focus();    
            }
          });
          return false;
        }

        doAddToCart($button, $form);
      });
    }
  };

  equalizeProductBlocks = (selectors) => {
    const w = SEC.eqWidth;
    if (!$$products || isExtView || !w || w === 'small' || w === 'small-plus') {
      return;
    }

    // проходим по всем карточкам товарав и построчно выравниваем нужные блоки
    let curTop;
    let prevTop = 0;
    let $$rowProducts = [];
    selectors = selectors || ['.c-product__title', ['.c-product__summary', 3], '.c-product__options'];
    for (let i=0, len=$$products.length; i<len; i++) {
      const $product = $$products[i];
      curTop = $product.offsetTop;
      if ($$rowProducts.length > 1 && curTop !== prevTop) {
        SEC.equalizeHeight($$rowProducts, selectors);
        $$rowProducts = [];
      }
      $$rowProducts.push($product);
      prevTop = curTop;
    }
    // прогоняем последнюю строку
    SEC.equalizeHeight($$rowProducts, selectors);
    activateFoldedSummaries();
  };

  processDescription = () => {
    const $desc = document.querySelector('.js-description');
    if (!$desc || hasClass($desc, 'category__description--fixed')) {
      return false;
    }

    const descHeight = $desc.offsetHeight;

    if (descHeight > 120) {
      const $anc = document.createElement('div');
     addClass($anc, 'unfold-description');
      // addClass($anc, 'unfold-link');
      let ancText = 'Развернуть описание';
      if (isBrandPage) {
        const $brandCrumb = document.querySelector('.breadcrumbs li:last-child a');
        if ($brandCrumb) {
          const brandName = $brandCrumb.innerText;
          ancText = 'Подробнее ' + ('AEIOUY'.indexOf(brandName[0]) === -1 ? 'о' : 'об');
          ancText += ' ' + brandName;
        }
      }
      $anc.innerHTML = '<button>' + ancText + '</button>';
      // $anc.innerHTML = '<a href="#" class="anchor">' + ancText + '</a>';
      $desc.appendChild($anc);

      $anc.addEventListener('click', (e) => {
        e.preventDefault();
        aniFadeOut($anc, () => {
          $anc.parentNode.removeChild($anc);
        });
        aniSlide.completed = false;
        aniSlide.complete = () => {
          removeClass($desc, 'is-folded');
          $desc.removeAttribute('style');
        };
        aniSlide.reverse();
        aniSlide.play();

        const path = window.location.pathname;
        const logEventName = '#unfoldDescription::' + path;
        SEC.clickyLog(logEventName);
      });

      addClass($desc, 'is-folded');

      const aniSlide = anime({
        targets: $desc,
        height: 118,
        duration: 500,
        easing: 'easeOutCubic'
      });
    }
  };

  processExtClouds = () => {
    SEC.foldContent('.c-product__why .text-cloud__content');
  };

  const processRating = () => {
    for (let i=0, len=$$products.length; i<len; i++) {
      addClass($$products[i], 'rating-' + (10 - i));
    }
  };

  moveDiscountBadges = () => {
    for (let i=0, len=$$products.length; i<len; i++) {
      const $discountBadge = $$products[i].querySelector('.product-price__discount');
      const $image = $$products[i].querySelector('.c-product__image');
      if (!$discountBadge || !$image) {
        continue;
      }
      removeClass($discountBadge, 'product-price__discount');
      addClass($discountBadge, 'discount_badge c-product__discount_badge');
      $image.appendChild($discountBadge);
    }
  };

  productsListGate = ($$cProducts) => {
    if (!$$cProducts) {
      return;
    }
    $$products = $$cProducts;
    initOptionSelectors();
    equalizeProductBlocks();
    bindCartButtonClicks();
  };

  init = () => {
    if (SEC.page.indexOf('category') === -1 && SEC.page.indexOf('index') === -1 && SEC.page.indexOf('search') === -1) {
      return false;
    }

    const $_products = document.querySelector('.c-products');

    isExtView = hasClass($_products, 'c-products--ext');

    $$products = document.querySelectorAll('.c-product');
    for (let i=0, len=$$products.length; i<len; i++) {
      const $commentsLink = $$products[i].querySelector('.js-comments');
      if (!$commentsLink) {
        continue;
      }
      $commentsLink.setAttribute('href', $commentsLink.getAttribute('href').replace('?comments', '#comments'));
    }

    initOptionSelectors();

    isBrandPage = window.location.pathname.indexOf('/brands/') === 0;

    const w = SEC.eqWidth;
    if (w) {
      // if (w === 'medium-plus' || w === 'large' || w === 'large-plus') {
      //   equalizeProductBlocks();
      // } else {
      if (w === 'small' || w === 'small-plus') {
        const $category = document.querySelector('.js-category');
        if ($category && hasClass($category, 'category-section')) {
          foldProductListBlocks();
        }
      }
      // }
    }

    moveDiscountBadges();

    processDescription();
    bindCartButtonClicks();

    if (isExtView && w && (w === 'small' || w === 'small-plus' || w === 'medium-plus')) {
      processExtClouds();
    }

    if (hasClass($_products, 'product-rating')) {
      processRating();
    }

    setTimeout(prepareGaVisibleProducts, 1000);

    const $productLinks = document.querySelectorAll('.c-product__image a, .c-product__title a');
    $productLinks.forEach(el => el.addEventListener('click', (e) => {
      // noinspection JSUnresolvedVariable
      if (typeof google_tag_manager === 'object') {
        e.preventDefault();
        // noinspection JSUnresolvedFunction
        const $link = e.target.href ? e.target : e.target.closest('a');
        const $product = $link.closest('.c-product');
        handleGaProductClick($product, $link.href);
        setTimeout(() => {
          if (!is_gaProductClickTracked) {
            window.location.assign($link.href);
          }
        }, 600);
      }
    }));
  };

  return {
    init,
    productsListGate,
    createGaCategoryLabelFromPath
  };

})();

document.addEventListener('DOMContentLoaded', function () {
  SEC.category.init();
});
