let SEC = SEC || {};

SEC.product = (function () {
  let $product;
  let $gallery;
  let $img;
  let $productInfo;
  let $thumbs;

    let $variants;

  let swiper;

  let init;
  let initAddToCart;
  let initArchived;
  let initGallery;
  let initInfoAnchors;
  let initPriceOffers;
  let initShippingCity;
  let initTabs;
  let initVideo;
  let loadComments;
  let loadComparisons;

  let createSwiperMarkup;
  let initSwiper;

  let moveDiscountBadge;
  let updateCommentsNumber;

  let createProductData;
  let getProductBrand;
  let getProductCode;
  let getProductPrice;
  let getProductVariant;
  // let getRefCategory;
  let recordGaAddToCart;
  let recordGaProductView;

  let productSlug;
  let preselectedThumbIdx;
  let comparisonsLoaded;
  let isHighRes = false;
  let productAddedToCartFlag;

//#region swiper
//<editor-fold desc="swiper">
  createSwiperMarkup = () => {
    if (!$img) {
      return false;
    }

    const swiperWidth = $img.offsetWidth + 2;

    const $swZoomContainer = document.createElement('div');
    addClass($swZoomContainer, 'swiper-zoom-container');
    wrap($img, $swZoomContainer);

    const $swContainer = document.createElement('div');
    addClass($swContainer, 'swiper-container swiper-container-main');
    $swContainer.style.maxWidth = swiperWidth + 'px';

    const $swWrapper = document.createElement('div');
    addClass($swWrapper, 'swiper-wrapper');
    $swContainer.appendChild($swWrapper);

    const $image = $img.closest('.product__image');

    if ($image) {
      addClass($image, 'swiper-slide');
      wrap($image, $swWrapper);
      wrap($swWrapper, $swContainer);
    }

    if ($thumbs.length > 1) {
      const $swiperBtnPrev = document.createElement('div');
      addClass($swiperBtnPrev, 'swiper-button-prev');
      $swContainer.appendChild($swiperBtnPrev);
      const $swiperBtnNext = document.createElement('div');
      addClass($swiperBtnNext, 'swiper-button-next');
      $swContainer.appendChild($swiperBtnNext);
      const $swiperPagination = document.createElement('div');
      addClass($swiperPagination, 'swiper-pagination');
      $swContainer.appendChild($swiperPagination);
    }

    if (!$thumbs) {
      return false;
    }

    const $cThumbs = document.querySelector('.js-thumbs');
    if (!$cThumbs) {
      return false;
    }

    addClass($cThumbs, 'swiper-container swiper-container-thumbs');
    $cThumbs.style.maxWidth = swiperWidth + 'px';

    const $thumbsWrapper = document.createElement('div');
    addClass($thumbsWrapper, 'swiper-wrapper');
    $cThumbs.appendChild($thumbsWrapper);

    for (let i=0, len=$thumbs.length; i<len; i++) {
      const $tImg = $thumbs[i].querySelector('img');
      if (!$tImg) {
        continue;
      }
      addClass($thumbs[i], 'swiper-slide');
      $thumbsWrapper.appendChild($thumbs[i]);

      if (i === 0) {
        continue;
      }

      const img = $tImg.getAttribute('src');
      const img2x = $tImg.getAttribute('srcSet');
      const imgAlt = $tImg.getAttribute('alt');

      const swImg = img.replace('/p/150/', '/p/465/');
      const swImgAlt = imgAlt || '';
      let swImg2x = '';
      if (img2x) {
        swImg2x = ' srcset="' + img2x.replace('/p/150/', '/p/465/') + '"';
      }

      const $slide = document.createElement('div');
      addClass($slide, 'product__image swiper-slide');
      $slide.innerHTML = '<div class="swiper-zoom-container"><img alt="' + swImgAlt + '" src="' + swImg + '"' + swImg2x + '></div>';

      $swWrapper.appendChild($slide);
    }

    $thumbs = $gallery.querySelectorAll('.thumb');
  };

  initSwiper = () => {
    createSwiperMarkup();

    for (let i=0, len=$thumbs.length; i<len; i++) {
      const $a = $thumbs[i].querySelector('a');
      $a.addEventListener('click', (e) => {
        e.preventDefault();
      })
    }

    const deactivateActiveThumb = () => {
      const $activeThumbs = $gallery.querySelectorAll('.product__thumb--active');
      removeClass($activeThumbs, 'product__thumb--active');
    };

    const activateThumb = (index) => {
      for (let i=0, len=$thumbs.length; i<len; i++) {
        if (i === index) {
          addClass($thumbs[i], 'product__thumb--active');
          if (!hasClass($thumbs[i], 'swiper-slide-visible')) {
            thumbsSwiper.slideTo(i);
          }
        }
      }
    };

    const thumbsSwiper = new Swiper('.swiper-container-thumbs', {
      slidesPerView: 4,
      spaceBetween: 14,
      loopedSlides: $thumbs.length,
      watchSlidesVisibility: true,
      watchSlidesProgress: true,
      on: {
        click: function() {
          if (thumbsSwiper.clickedIndex !== swiper.realIndex) {
            swiper.slideTo(thumbsSwiper.clickedIndex);
          }
        }
      }
    });

    const swiperOptions = {
      slidesPerView: 1,
      loop: true,
      loopedSlides: $thumbs.length,
      pagination: {
        el: '.swiper-pagination',
        clickable: true,
      },
      navigation: {
        nextEl: '.swiper-button-next',
        prevEl: '.swiper-button-prev',
      },
      on: {
        init: function() {
          if (preselectedThumbIdx) {
            this.slideTo(preselectedThumbIdx);
          }
        },
        slideChangeTransitionStart: deactivateActiveThumb,
        slideChangeTransitionEnd: function() {
          deactivateActiveThumb();
          activateThumb(this.realIndex);
        }
      },
      zoom: {
        maxRatio: 2
      }
    };

    if ($thumbs.length < 2) {
      swiperOptions['loop'] = false;
      swiperOptions['navigation'] = {};
      swiperOptions['pagination'] = {};
    }

    swiper = new Swiper('.swiper-container-main', swiperOptions);
  };
//</editor-fold>
//#endregion

//#region gallery
//<editor-fold desc="gallery">
  initGallery = () => {
    if (!$img) {
      return false;
    }

    // проверяем текущее изображение - highres или нет (для changeImage)
    const imgSrc = $img.currentSrc || $img.src;
    if ($img.getAttribute('srcset') && imgSrc.indexOf($img.getAttribute('srcset').split(' ')[0]) !== -1) {
      isHighRes = true;
    }

    // initSwiper();
  };
//</editor-fold>
//#endregion

  loadComparisons = () => {
    if (!$productInfo) {
      return false;
    }
    const $comparisons = $productInfo.querySelector('.js-comparisons');
    if (!$comparisons) {
      return false;
    }

    axios
      .get(window.location.pathname + 'get-comparisons/')
      .then((response) => {
        $comparisons.innerHTML = response.data;
        const $$cProducts = $comparisons.querySelectorAll('.c-product');
        if ($$cProducts) {
          SEC.category.productsListGate($$cProducts);
        }
        comparisonsLoaded = true;
      });
  };

  moveDiscountBadge = () => {
    if (!SEC.$shopActions || !$gallery) {
      return false;
    }
    const $discountBadge = SEC.$shopActions.querySelector('.js-discount');
    if (!$discountBadge) {
      return false;
    }

    removeClass($discountBadge, 'product-price__discount');
    addClass($discountBadge, 'discount_badge product__discount_badge');
    $gallery.appendChild($discountBadge);
  };

  initInfoAnchors = () => {
    const $anchors = document.querySelector('.js-info-anchors');
    if (!$anchors) {
      return false;
    }

    const $commentsLink = $anchors.querySelector('.js-comments-link');
    if ($commentsLink) {
      $commentsLink.setAttribute('href', '#comments');
      addClass($commentsLink, 'anchor');
    } else {
      return false;
    }

    const activateAnchor = ($el) => {
      if (!$el || !$el.getAttribute('href')) {
        return false;
      }

      const target = $el.getAttribute('href').split('#')[1];
      const $nav = $productInfo.querySelector('[data-rel-tab=' + target + ']');
      if ($nav) {
        activateTab($nav);
      }

      anime({
        targets: 'html, body',
        scrollTop: document.querySelector('.product__' + target).offsetTop - 100,
        easing: 'easeOutCubic',
        duration: 500
      })
    };

    const handleAnchorClick = ($el) => {
      const anchor = $el.getAttribute('href').substr(1);
      const logEventName = '#product__clickAnchor-' + anchor + '::' + productSlug;
      SEC.clickyLog(logEventName);
      window.dataLayer.push({
        'event': logEventName
      });

      activateAnchor($el);
    };

    $anchors.addEventListener('click', (e) => {
      e.preventDefault();

      const handlers = [
        ['a', handleAnchorClick]
      ];
      dispatchEvents(e, handlers);
    });
  };

//#region comments
//<editor-fold desc="comments">
  updateCommentsNumber = (num) => {
    const $commentsTabNav = $productInfo.querySelector('[data-rel-tab=comments]');
    if ($commentsTabNav) {
      $commentsTabNav.innerHTML += '<span class="product-reviews-amount">(' + num + ')</span>';
    }
  };

  loadComments = () => {
    if (!$productInfo) {
      return false;
    }

    const $commentsLink = $productInfo.querySelector('.js-comments-link');
    if (!$commentsLink) {
      updateCommentsNumber(0);
      return false;
    }

    let $$comments;

    const $form = $productInfo.querySelector('.js-comment-form');

    const $commentsBlock = document.createElement('ol');
    addClass($commentsBlock, 'product-comments product-comments-inline');

    $commentsLink.parentNode.insertBefore($commentsBlock, $commentsLink);

    const showAllComments = () => {
      if (!$$comments) {
        return false;
      }

      for (let i=0, len=$$comments.length; i<len; i++) {
        if (hasClass($$comments[i], 'hidden')) {
          removeClass($$comments[i], 'hidden');
        }
      }
    };

    const processComments = (html) => {
      $commentsBlock.innerHTML = $commentsBlock.innerHTML + html;

      $$comments = $commentsBlock.querySelectorAll('.product-comment');
      if (!$$comments) {
        return;
      }

      updateCommentsNumber($$comments.length);

      const $$rootComments = $commentsBlock.querySelectorAll('.product-comment:not(.product-comment-child)');
      if ($$rootComments.length > 5) {
        let hideCounter;
        for (let i=0, len=$$comments.length; i<len; i++) {
          if (hideCounter) {
            addClass($$comments[i], 'hidden');
            hideCounter++;
          } else {
            if ($$comments[i] === $$rootComments[3]) {
              addClass($$comments[i], 'hidden');
              hideCounter = 1;
            }
          }
        }
        const $a = $commentsLink.querySelector('a');
        addClass($commentsLink, 'link-to-comments--anchored');
        addClass($a, 'anchor');
        $a.innerHTML = 'Читать дальше  (+' + hideCounter + ')';
        $a.addEventListener('click', (e) => {
          e.preventDefault();
          showAllComments();
          $a.remove();
        });
      } else {
        addClass($commentsLink, 'hidden');
      }

      //<a href="#add-comment" class="comment-form-anchor perm anchor">Есть вопрос или отзыв/комментарий об этом товаре?</a>
      const $linkToForm = document.createElement('div');
      addClass($linkToForm, 'link-to-form');
      const $linkToFormAnchor = document.createElement('a');
      $linkToFormAnchor.setAttribute('href', '#comment-form');
      $linkToForm.appendChild($linkToFormAnchor);
      addClass($linkToFormAnchor, 'perm anchor');
      $linkToFormAnchor.innerHTML = 'Есть вопрос или отзыв об этом товаре?';
      $commentsBlock.parentNode.insertBefore($linkToForm, $commentsBlock);
      $linkToFormAnchor.addEventListener('click', () => {
        SEC.scrollTo($form, () => {
          const $firstTextField = $form.querySelector('input[type=text]');
          if ($firstTextField) {
            $firstTextField.focus();
          }
        });
      });
    };

    SEC.showLoaderAfter($commentsLink);

    axios
      .get(window.location.pathname + 'get-comments/')
      .then((response) => {
        SEC.removeLoaderFrom($productInfo);
        processComments(response.data);
      })

  };
//</editor-fold>
//#endregion

  initPriceOffers = () => {
    if (!SEC.$shopActions) {
      return false;
    }
    const $offers = SEC.$shopActions.querySelector('.js-price-offers');
    const $anc = SEC.$shopActions.querySelector('.js-price-offers-anchor');
    if (!$offers || !$anc) {
      return false;
    }

    $offers.style.height = 'auto';
    const height = $offers.clientHeight;
    $offers.style.height = '0px';

    $anc.addEventListener('click', (e) => {
      e.preventDefault();
      anime({
        targets: $offers,
        height: height,
        duration: 250,
        easing: 'linear'
      });
      const logEventName = '#product__priceOffersClick::' + productSlug;
      SEC.clickyLog(logEventName);
      window.dataLayer.push({
        'event': logEventName
      })
    });
  };

//#region tabs
//<editor-fold desc="tabs">
  const navActiveClass = 'c-tabs-nav__item--active';
  const tabActiveClass = 'c-tab--active';
  const tabHiddenClass = 'c-tab--hidden';

  let $$tabNavs;
  let $$tabs;

  const activateTab = ($nav) => {
    if (hasClass($nav, navActiveClass)) {
      return false;
    }

    deactivateAllTabs();

    addClass($nav, navActiveClass);
    const relTab = $nav.dataset.relTab;
    const relTabClass = 'product__' + relTab;

    for (let i=0, len=$$tabs.length; i<len; i++) {
      if (hasClass($$tabs[i], relTabClass)) {
        addClass($$tabs[i], tabActiveClass);
      } else {
        addClass($$tabs[i], tabHiddenClass);
      }
    }

    if (relTab === 'description' && !comparisonsLoaded) {
      loadComparisons();
    }

    const logEventName = '#product__activateTab-' + relTab + '::' + productSlug;
    SEC.clickyLog(logEventName);
    window.dataLayer.push({
      'event': logEventName
    })
  };

  const deactivateAllTabs = () => {
    for (let i=0, len=$$tabNavs.length; i<len; i++) {
      removeClass($$tabNavs[i], navActiveClass);
    }
    for (let i=0, len=$$tabs.length; i<len; i++) {
      removeClass($$tabs[i], tabActiveClass);
      removeClass($$tabs[i], tabHiddenClass);
    }
  };

  initTabs = () => {
    if (!$productInfo) {
      return false;
    }

    $$tabNavs = $productInfo.querySelectorAll('.js-tab-nav');
    $$tabs = $productInfo.querySelectorAll('.js-tab');

    let tabActivated = false;

    for (let i=0, len=$$tabNavs.length; i<len; i++) {
      $$tabNavs[i].addEventListener('click', (e) => {
        e.preventDefault();
        activateTab($$tabNavs[i]);
      });

      // если в URL есть хэш, проверяем не нужно ли активировать этот таб
      if (window.location.hash) {
        let relTab = $$tabNavs[i].dataset.relTab;
        if (relTab && window.location.hash.indexOf(relTab) !== -1) {
          activateTab($$tabNavs[i]);
          tabActivated = true;
          SEC.scrollTo($$tabNavs[i]);
        }
      }
    }

    if (!tabActivated) {
      const $inactiveTabs = $productInfo.querySelectorAll('.c-tab:not(.c-tab--active)');
      for (let i=0, len=$inactiveTabs.length; i<len; i++) {
        addClass($inactiveTabs[i], tabHiddenClass);
      }
    }
  };
//</editor-fold>
//#endregion

//#region video
//<editor-fold desc="video">
  initVideo = () => {
    if (!$productInfo) {
      return false;
    }

    const $vLinks = $productInfo.querySelectorAll('a[href*="youtube"], a[href*="vimeo"]');
    const $desc = $productInfo.querySelector('.js-tab-description');

    if (!$vLinks || !$desc) {
      return false;
    }

    let $videos;

    $vLinks.forEach(($el) => {
      const $div = document.createElement('div');
      addClass($div, 'product__video');

      $div.innerHTML = `<iframe
        src="` + $el.getAttribute('href') + `"
        frameborder="0"
        webkitAllowFullScreen
        mozallowfullscreen
        allowFullScreen
        class="product__video__v"
        ></iframe>`;

      if ($el.innerHTML[0] === '+') {
        $el.innerHTML = $el.innerHTML.substr(1);
        $el.parentNode.replaceChild($div, $el);
      } else {
        const $li = $el.closest('li');
        addClass($li, 'hidden');
        if (!$videos) {
          $videos = document.createElement('div');
          addClass($videos, 'product__videos');
          $desc.appendChild($videos);
        }
        $videos.appendChild($div);
      }

    });
  };
//</editor-fold>
//#endregion

//#region GA
//<editor-fold desc="GA">
  getProductBrand = () => {
    const $brand = document.querySelector('.js-brand');
    if ($brand && $brand.dataset.brand) {
      return $brand.dataset.brand;
    }
    return '';
  };

  getProductCode = () => {
    const $code = document.querySelector('.js-product-code');
    if ($code) {
      return $code.innerText.split(' ')[1];
    }
    return '';
  };

  getProductPrice = () => {
    if (!SEC.$shopActions) {
      return false;
    }
    const $price = SEC.$shopActions.querySelector('.js-price');
    const $priceFrom = SEC.$shopActions.querySelector('.product-price__from');
    if ($price && !$priceFrom) {
      return $price.innerText;
    }
    return null;
  };

  getProductVariant = () => {
    if (!$variants || !hasClass($variants, 'is-selected')) {
      return null;
    }

    return $variants.querySelector('option[selected]').innerText;
  };

/*
  getRefCategory = () => {
    const $pTitle = document.querySelector('.js-product-name');
    const refCategory = $pTitle.dataset.refCategory;
    if (!$pTitle || !refCategory) {
      return '';
    }

    return SEC.category.createGaCategoryLabelFromPath(refCategory);
  };
*/

  createProductData = () => {
    const productData = {
      'name': productSlug,
      'id': getProductCode(),
      'brand': getProductBrand()
    };

    const variant = getProductVariant();
    if (variant) {
      productData['variant'] = variant;
    }

    const price = getProductPrice();
    if (price) {
      productData['price'] = price;
    }

    return productData;
  };

  recordGaAddToCart = ($addToCartForm) => {
    let productData = createProductData();
    productData['quantity'] = 1;

    window.dataLayer.push({
      'ecommerce': {
        'add': {
          'products': [
            productData
          ]
        }
      },
      'event': 'gtm-ee-event',
      'gtm-ee-event-category': 'Enhanced Ecommerce',
      'gtm-ee-event-action': 'Adding a Product to a Cart',
      'gtm-ee-event-non-interaction': 'False',

      'eventCallback': () => {
        productAddedToCartFlag = true;
        $addToCartForm.submit();
      }
    });
  };

  recordGaProductView = () => {
    let productData = createProductData();

    window.dataLayer.push({
      'ecommerce': {
        'detail': {
          // 'actionField': {'list': getRefCategory()},
          'products': [
            productData
          ]
        }
      },
      'event': 'gtm-ee-event',
      'gtm-ee-event-category': 'Enhanced Ecommerce',
      'gtm-ee-event-action': 'Product Details',
      'gtm-ee-event-non-interaction': 'True',
    });
  };
//</editor-fold>
//#endregion

  initAddToCart = () => {
    // const $addToCartForm = document.querySelector('.js-add-to-cart-form');

    // if (!$addToCartForm) {
    //   return false;
    // }

    // const $btn = $addToCartForm.querySelector('button[type=submit]');
    // if ($btn) {
    //   $btn.addEventListener('click', (e) => {
        
    //     setTimeout(() => {
    //         addClass($btn, 'in-process');
    //         SEC.freezeButton($btn, 5000);
    //     }, 100);
    //   });
    // }

    // $addToCartForm.addEventListener('submit', (e) => {
    //   // noinspection JSUnresolvedVariable

    //   if (typeof google_tag_manager === 'object') {
    //         e.preventDefault();
    //         recordGaAddToCart($addToCartForm);
    //         setTimeout(() => {
    //             if (!productAddedToCartFlag) {
    //                 $addToCartForm.submit();
    //             }
    //         }, 600);
    //   }
    // })
  };

//#region shipping-city
  initArchived = () => {
    const $archivedMsg = document.querySelector('.js-archived-msg');
    if (!$archivedMsg) {
      return false;
    }

    const $p = document.createElement('p');
    addClass($p, 'note');
    const $anchor = document.createElement('a');
    addClass($anchor, 'anchor perm');
    $anchor.setAttribute('href', '#');
    $anchor.innerText = '👇 Хочу посмотреть именно этот товар, сделайте ярче'
    $p.appendChild($anchor);
    $archivedMsg.appendChild($p);

    $anchor.addEventListener('click', (e) => {
      e.preventDefault();
      addClass($product, 'product--resurrected');
      $p.parentNode.removeChild($p);
    });
  };

  initShippingCity = () => {
    const $productShipping = document.querySelector('.js-product-shipping');
    if (!$productShipping) {
      return false;
    }

    const makeRequest = (cityId) => {
      let url = '/geo/get-shipping-options/';
      if (cityId) {
        url += cityId + '/';
      }
      axios
        .get(url)
        .then((response) => {
          aniFadeOut($productShipping, () => {
            $productShipping.innerHTML = response.data;
            aniFadeIn($productShipping);
          });
        });
    }

    if ($productShipping.dataset.type === 'fallback') {
      makeRequest();
    }

    const handleCityLinkClick = () => {
      SEC.shade(null, shadeClose);
      removeClass($container, '--hidden');
      addClass($container, 'invisible');
      aniFadeIn($container, () => {
        SEC.citySelect.activate(citySelectCallback);
      }, {duration: 200});

      const logEventName = '#product__ShippingCityClick::' + productSlug;
      SEC.clickyLog(logEventName);
    };

    const handleSelfmskClick = () => {
      const $notes = $productShipping.querySelector('.js-notes');
      if (!$notes) {
        return;
      }
      removeClass($notes, '--hidden');
      addClass($notes.parentNode, '--expanded');

      const height = $notes.offsetHeight;
      $notes.style.height = 0;
      $notes.style.opacity = 0;
      const aniSlide = anime({
        targets: $notes,
        height: height,
        opacity: 1,
        duration: 250,
        easing: 'easeOutCubic'
      });

    };

    $productShipping.addEventListener('click', (e) => {
      const handlers = [
        ['.js-shipping-city', handleCityLinkClick],
        ['.js-selfmsk .anchor', handleSelfmskClick],
        ['.js-selfmsk .contacts-link', ($el) => {
          window.location = $el.getAttribute('href');
        }]
      ];
      dispatchEvents(e, handlers);
    });

    let cityId;

    const $container = document.createElement('div');
    addClass($container, 'city-select --hidden');
    document.body.append($container);
    const $btn = document.createElement('button');
    addClass($btn, 'btn btn-notable');
    $btn.innerText = 'Сохранить';
    SEC.freezeButton($btn);

    const shadeClose = () => {
      SEC.citySelect.reset();
      SEC.freezeButton($btn);
      addClass($container, '--hidden');
    };

    $btn.addEventListener('click', () => {
      SEC.unShade();
      shadeClose();
      SEC.showLoaderAbove($productShipping);

      makeRequest(cityId);

      const logEventName = '#product__ShippingCitySave::' + cityId;
      SEC.clickyLog(logEventName);
    });

    const citySelectCallback = (selectedCityId) => {
      const $btn = $container.querySelector('.btn');
      if (selectedCityId) {
        cityId = selectedCityId;
        SEC.unfreezeButton($btn);
        } else {
        SEC.freezeButton($btn);
      }
    };

    axios
      .get('/geo/get-cities/')
      .then((response) => {
        const citiesJSON = response.data;
        if (SEC.citySelect) {
          SEC.citySelect.createForm($container, citiesJSON);
          $container.append($btn);
        }
      });
  };
//#endregion

  init = () => {
    if (SEC.page.indexOf('product') === -1) {
      return false;
    }

    productSlug = window.location.pathname.split('/')[2];

    $product = document.querySelector('.js-product');
    $gallery = document.querySelector('.js-gallery');
    if ($gallery) {
      $img = $gallery.querySelector('.js-image');
      $thumbs = $gallery.querySelectorAll('.thumb');
    }
    if (SEC.$shopActions) {
      $variants = SEC.$shopActions.querySelector('.js-variants');
    }
    $productInfo = document.querySelector('.js-product-info');

    // loadThumbs();

    // initVariants(); // must be before recordGaProductView()

    initAddToCart();

    initGallery();
    initInfoAnchors();
    initPriceOffers();
    initTabs();
    initVideo();
    initShippingCity();

    loadComments();

    // if (!window.location.hash || window.location.hash.indexOf('comments') === -1) {
    //   loadComparisons();
    // }

    moveDiscountBadge();

    if (hasClass($product, 'product-archived')) {
      initArchived();
    }

    // recordGaProductView();
  };

  return {
    init
  };

})();

SEC.gallery = (function () {
    let $gallery;
    let $image;
    let $prevButton;
    let $nextButton;
    let $thumbsContainer;
    let $$thumbs;

    let mainEmblaApi;
    let thumbsEmblaApi;

    let removeThumbClickHandlers;
    let removeToggleThumbActive;

    const THUMB_ACTIVE_CLASS = 'product__thumb--active';
 
    const updateCaption = (caption) => {
        const $caption = $gallery.querySelector('.js-image-caption');
        if ($caption && $caption.textContent !== caption) {
            aniFade($caption, caption);
        }
    };

    const loadThumbs = () => {
        if (!$$thumbs) {
            return false;
        }
        $$thumbs.forEach(($thumb, index) => {
            const $img = $thumb.querySelector('img');
            if (!$img) {
                return;
            }
            const src = $img.dataset.src;
            const srcset = $img.dataset.srcset;
            if (srcset) {
                $img.setAttribute('srcset', srcset);
            }
            if (src) {
                $img.setAttribute('src', src);
            }
        })
    };
    
    const addThumbsClickHandlers = () => {
        let slidesThumbs = thumbsEmblaApi.slideNodes();
        const scrollToIndex = (index) => {
            mainEmblaApi.scrollTo(index);
        };

        slidesThumbs.forEach((slideThumb, index) => {
            slideThumb.addEventListener(
                'click', (e) => {
                    e.preventDefault();
                    // обновляем slidesThumbs, т.к. они могли измениться (filtered) 
                    slidesThumbs = thumbsEmblaApi.slideNodes();
                    scrollToIndex(slidesThumbs.indexOf(slideThumb));
                }, false
            );
        });

        return () => {
            slidesThumbs.forEach((slideNode, index) => {
                slideNode.removeEventListener(
                    'click', scrollToIndex[index], false
                );
            });
        }
    };

    const addToggleThumbActive = () => {
        const toggleThumbActive = () => {
            const slidesThumbs = thumbsEmblaApi.slideNodes();
            const selected = mainEmblaApi.selectedScrollSnap();
            const previous = mainEmblaApi.previousScrollSnap();
            thumbsEmblaApi.scrollTo(selected);
            removeClass(slidesThumbs[previous], THUMB_ACTIVE_CLASS);
            addClass(slidesThumbs[selected], THUMB_ACTIVE_CLASS);
        };

        mainEmblaApi.on('select', toggleThumbActive);
    };

    const createSlide = (src, srcset) => {
        if (!src && !srcset) {
            return;
        }
        const $slide = document.createElement('div');
        addClass($slide, 'product__image');
        const $link = document.createElement('a');
        const $img = document.createElement('img');
        let filename = srcset ? srcset.split(' ')[0] : src;
        $link.setAttribute('href', filename.replace('/p/150/', '/p/465/'));
        if (src) {
            $img.setAttribute('src', src.replace('/p/150/', '/p/465/'));
        }
        if (srcset) {
            $img.setAttribute('srcset', srcset.replace('/p/150/', '/p/465/'));
        }
        $link.appendChild($img);
        $slide.appendChild($link);
        return $slide;
    };

    const checkPrevNextButtons = () => {
        const toHide = [];
        const toShow = [];
        if (mainEmblaApi.canScrollPrev()) {
            if (hasClass($prevButton, 'hidden')) {
                toShow.push($prevButton);
            }
        } else {
            if (!hasClass($prevButton, 'hidden')) {
                toHide.push($prevButton);
            }
        };
        if (mainEmblaApi.canScrollNext()) {
            if (hasClass($nextButton, 'hidden')) {
                toShow.push($nextButton);
            }
        } else {
            if (!hasClass($nextButton, 'hidden')) {
                toHide.push($nextButton);
            }
        };
        toHide.forEach(($button, index) => {
            addClass($button, 'in-process');
            aniFadeOut($button, () => {
                removeClass($button, 'in-process');
                setTimeout(() => {
                    addClass($button, 'hidden');
                    $button.style.removeProperty('opacity');
                  }, 500);
            });
        });
        toShow.forEach(($button, index) => {
            removeClass($button, 'hidden');
            if (hasClass($button, 'invisible')) {
              return;
            }
            addClass($button, 'in-process');
            aniFadeIn($button, () => {
                removeClass($button, 'in-process');
            });
        });
    };

    const handleSlideChanged = () => {
        checkPrevNextButtons();        
        setTimeout(() => {
            const $selectedThumb = $thumbsContainer.querySelector('.product__thumb--active');
            if ($selectedThumb) {
                const $link = $selectedThumb.querySelector('a[title]');
                if ($link) {
                    updateCaption($link.getAttribute('title'));
                }
            }
        }, 100);
    };

    const createMainSlider = () => {
        if (!$image || !$$thumbs) {
            return;
        }
        const $wrapper = document.createElement('div');
        addClass($wrapper, 'product__images');
        wrap($image.parentNode, $wrapper);
        const $viewport = document.createElement('div');
        addClass($viewport, 'product__images-viewport');
        wrap($wrapper, $viewport);
        const $slider = document.createElement('div');
        addClass($slider, 'product__images-slider');
        wrap($viewport, $slider);
        $$thumbs.forEach(($thumb, index) => {
            if (index === 0) {
                return;
            }
            const $img = $thumb.querySelector('img');
            if ($img) {
                const $slide = createSlide($img.dataset.src, $img.dataset.srcset);
                $wrapper.appendChild($slide);
            }
        });
        $prevButton = document.createElement('button');
        addClass($prevButton, 'slider__button slider__button-prev hidden');
        $slider.appendChild($prevButton);
        $nextButton = document.createElement('button');
        addClass($nextButton, 'slider__button slider__button-next hidden');
        $slider.appendChild($nextButton);
        if (!isTouchDevice()) {
            $slider.addEventListener('mouseenter', () => {
                removeClass([$prevButton, $nextButton], 'invisible');
            });
            $slider.addEventListener('mouseleave', () => {
                const buttons = [$prevButton, $nextButton];
                buttons.forEach((button, index) => {
                    button.style.removeProperty('opacity');
                    addClass(button, 'invisible');
                });
            });
        }
        const EMBLA_MAIN_OPTIONS = {
            container: $wrapper,
            slides: '.product__image:not(.hidden)'
        };
        mainEmblaApi = EmblaCarousel($viewport, EMBLA_MAIN_OPTIONS);
        mainEmblaApi.on('init', () => {
            checkPrevNextButtons();
        });
        mainEmblaApi.on('select', handleSlideChanged);
        mainEmblaApi.slideNodes().forEach(($slide, index) => {
            $slide.addEventListener('click', (e) => {
                e.preventDefault();
            });
        });
        $prevButton.addEventListener('click', mainEmblaApi.scrollPrev, false);
        $nextButton.addEventListener('click', mainEmblaApi.scrollNext, false);
    };

    const createThumbsSlider = () => {
        if ($thumbsContainer) {
            const imageWidth = $image.offsetWidth;
            const $wrapper = document.createElement('div');
            addClass($wrapper, 'product__thumbs-viewport');
            $wrapper.style.maxWidth = imageWidth + 'px';
            wrap($thumbsContainer, $wrapper);
            const EMBLA_OPTIONS_THUMBS = {
                align: 'start',
                container: $thumbsContainer,
                containScroll: 'keepSnaps',
                dragFree: true,                
                slides: '.thumb:not(.hidden)'
            }
            thumbsEmblaApi = EmblaCarousel($wrapper, EMBLA_OPTIONS_THUMBS);
        }
    };

    const filterSlidesAndThumbs = (visibleVariants) => {
        const $$images = $gallery.querySelectorAll('.product__image');
        let firstVisibleIndex;
        $$thumbs.forEach(($thumb, index) => {
            if ($thumb.dataset.variant !== '*' &&
                visibleVariants.indexOf($thumb.dataset.variant) === -1) {
                    removeClass($thumb, 'product__thumb--active');
                    addClass($thumb, 'hidden');
                    addClass($$images[index], 'hidden');
            } else {
                if (hasClass($thumb, 'hidden')) {
                    removeClass($thumb, 'hidden');
                    removeClass($$images[index], 'hidden');
                }
                if (!firstVisibleIndex) {
                    firstVisibleIndex = index;
                }
            }
        });
        mainEmblaApi.reInit();
        thumbsEmblaApi.reInit();
        mainEmblaApi.scrollTo(0);
        mainEmblaApi.emit('select');
        mainEmblaApi.emit('settle');
    };

    const scrollSliderToVariant = (variant) => {
        const slidesThumbs = thumbsEmblaApi.slideNodes();

        for (let i=0, len=slidesThumbs.length; i<len; i++) {
            if (slidesThumbs[i].dataset.variant === variant) {
                mainEmblaApi.scrollTo(i);
                break;
            }
        }
    };

    const init = () => {
        $gallery = document.querySelector('.js-gallery');
        if (!$gallery) {
            return;
        }
        $image = $gallery.querySelector('.js-image');
        $thumbsContainer = $gallery.querySelector('.js-thumbs');
        if ($thumbsContainer) {
            $$thumbs = $thumbsContainer.querySelectorAll('.thumb');
            if ($$thumbs.length > 1) {
                createMainSlider();
                createThumbsSlider();
                removeThumbClickHandlers = addThumbsClickHandlers();
                removeToggleThumbActive = addToggleThumbActive();
                if (thumbsEmblaApi) {
                    thumbsEmblaApi.on('destroy', removeThumbClickHandlers);
                    thumbsEmblaApi.on('destroy', removeToggleThumbActive);
                }
            }
        }
        loadThumbs();
    };

    return {
        filterSlidesAndThumbs,
        scrollSliderToVariant,
        init
    };
})();

SEC.options = (function () {
    let $filteredSelect;
    let $form;
    let $variants;
    let $variantSelect;
    let optionsData;

    const updateBtn = (isInCart) => {
        const $btn = $form.querySelector('button[type=submit]');
        if (!$btn) {
            return false;
        }

        const btnClassName = 'btn--processed';

        if (isInCart) {
            if (!hasClass($btn, btnClassName)) {
                anime.timeline({
                    targets: $btn,
                    duration: 150,
                    easing: 'linear'
                })
                .add({
                    scale: 1.3,
                    complete: () => {
                        $btn.innerText = 'В корзине';
                        addClass($btn, btnClassName);
                        const $cartLink = document.createElement('div');
                        addClass($cartLink, 'cart-link');
                        $cartLink.innerHTML = '<a href="/cart/">Перейти в корзину</a>';
                        $btn.parentNode.appendChild($cartLink);
                    }
                })
                .add({
                    scale: 1,
                })
                .add({
                    delay: 5000,
                    scale: 1.3,
                    complete: () => {
                        $btn.innerText = 'Добавить ещё';
                        removeClass($btn, btnClassName);
                    }
                  })
                .add({
                    scale: 1,
                })
            }
        } else {
            if (hasClass($btn, btnClassName)) {
                removeClass($btn, btnClassName);
                $btn.innerText = 'Добавить в корзину';
            }
        }
    };

    const updatePrice = (minPrice, basePrice, differentPrices) => {
        if (!SEC.$shopActions) {
            return;
        }
        const $price = SEC.$shopActions.querySelector('.js-price');
        if (!$price) {
            return;
        }
        const $priceBlock = $price.closest('.product-price');
        const $basePrice = $priceBlock.querySelector('.js-base-price');
        const $priceFrom = $priceBlock.querySelectorAll('.product-price__from');
        const currentPrice = parseInt($price.innerHTML);
        if (minPrice !== currentPrice) {
            addClass($priceBlock, 'invisible');
            setTimeout(() => {
                $price.innerText = minPrice;
                if ($basePrice) {
                    const currentBasePrice = parseInt($basePrice.innerHTML);
                    if (basePrice !== currentBasePrice) {
                        $basePrice.innerText = basePrice;
                    }
                }
                removeClass($priceBlock, 'invisible');
            }, 500);
        }
        if ($priceFrom) {
            if (differentPrices) {
                setTimeout(() => {
                  removeClass($priceFrom, 'hidden');
                }, 500);
            } else {
                setTimeout(() => {
                  addClass($priceFrom, 'hidden');
                }, 500);
            }
        }        
    };

    const getOptionTypeAndLabelByValue = (optionValue) => {
        if (!optionsData) {
            return;
        }
        optionValue = 'k' + optionValue;
        for (let i=0, len=optionsData.length; i<len; i++) {
            const optionValues = optionsData[i][3];
            if (optionValue in optionValues) {
                return [optionsData[i][1], optionValues[optionValue]];
            }
        }
        return null;
    };

    const makeFilteredSelectRequired = () => {
        if (!$filteredSelect) {
            return;
        }
        $filteredSelect.required = true;
    };

    const hideVariantSelect = () => {
        if (!$variantSelect) {
            return;
        }
        addClass($variantSelect, 'hidden');
        if ($variantSelect.required) {
            $variantSelect.required = false;
            makeFilteredSelectRequired();
        }
    }

    const createFilteredVariantSelect = () => {
        if (!$variantSelect) {
            return;
        }
        if (!$filteredSelect) {
            $filteredSelect = document.createElement('select');
            $filteredSelect.setAttribute('name', '_variant');
            addClass($filteredSelect, 'second-option');
            $filteredSelect.addEventListener('change', () => {
                if (!$filteredSelect.validity.valueMissing) {
                    $variantSelect.value = $filteredSelect.value;
                    if (hasClass($filteredSelect, 'is-awaiting-choose')) {
                        removeClass($filteredSelect, 'is-awaiting-choose');
                        doFormSubmit();
                    };
                }
                SEC.gallery.scrollSliderToVariant($filteredSelect.value);
            });
            $variantSelect.parentNode.insertBefore($filteredSelect, $variantSelect);
        }        
    };

    const setFilteredVariantSelect = (optionValue) => {
        if (!$filteredSelect) {
            return;
        }
        $filteredSelect.value = optionValue;
    };

    const updateFilteredVariantSelect = (optionValue) => {
        if (!$filteredSelect) {
            return;
        }
        addClass($filteredSelect, 'invisible');

        setTimeout(() => {
            $filteredSelect.innerHTML = '';
            const $variantSelectOptions = $variantSelect.querySelectorAll('option');
            const $firstOption = document.createElement('option');
            $firstOption.value = '';
            $filteredSelect.appendChild($firstOption);
            let minPrice;
            let basePrice;
            let differentPrices;
            let filteredOptions = [];
            for (let i=0, len=$variantSelectOptions.length; i<len; i++) {
                const $option = $variantSelectOptions[i];
                if ($option.dataset.options) {
                    const options = $option.dataset.options.split(',');
                    if (options.indexOf(optionValue) === -1) {
                        continue;
                    }
                    filteredOptions.push($option.value);
                    let optionBasePrice;
                    if ($option.dataset.basePrice) {
                        optionBasePrice = parseInt($option.dataset.basePrice);
                    }
                    const $_option = document.createElement('option');
                    if ($option.dataset.price) {
                        const optionPrice = parseInt($option.dataset.price);
                        $_option.dataset.price = optionPrice;
                        if (!minPrice) {
                            minPrice = optionPrice;
                        } else if (optionPrice !== minPrice) {
                            differentPrices = true;
                            minPrice = Math.min(minPrice, optionPrice);
                        }
                        if (optionBasePrice) {
                            basePrice = optionBasePrice;
                        }
                    }
                    $_option.setAttribute('value', $option.value);
                    const remainingOptions = options.filter(e => e !== optionValue);
                    if (remainingOptions.length) {
                        $_option.dataset.options = remainingOptions.join(',');
                        const [optionsType, optionLabel] = 
                            getOptionTypeAndLabelByValue(remainingOptions[0]);
                        $_option.innerHTML = optionLabel[1];
                        if (optionsType) {
                            $firstOption.innerHTML = 'Выберите ' + optionsType;
                        }
                    }
                    $filteredSelect.appendChild($_option);
                }
            }
            if (differentPrices) {
                const $_options = $filteredSelect.querySelectorAll('option');
                for (let i=0, len=$_options.length; i<len; i++) {
                    const $_option = $_options[i];
                    if ($_option.dataset.price) {
                        $_option.innerText += ' – ' + $_option.dataset.price + ' ₽';
                    }
                }
            }
            if (filteredOptions.length === 1) {
                setFilteredVariantSelect(filteredOptions[0]);
            }
            if (minPrice) {
              updatePrice(minPrice, basePrice, differentPrices);
            }
            $filteredSelect.dispatchEvent(new Event('change'));
            SEC.gallery.filterSlidesAndThumbs(filteredOptions);    

            removeClass($filteredSelect, 'invisible');
        }, 500);
    };

    const activateOption = ($optionBtn, isInit=false) => {
        const $activeOptionBtn = $optionBtn.parentNode.querySelector('button.is-active');
        if ($optionBtn === $activeOptionBtn) {
            return;
        }
        removeClass($activeOptionBtn, 'is-active');
        addClass($optionBtn, 'is-active');
        if ($optionBtn.value) {
            $variantSelect.value = $optionBtn.value;
            if (!isInit) {
                SEC.gallery.scrollSliderToVariant($optionBtn.value);
            }
        }
        updateFilteredVariantSelect($optionBtn.dataset.option);
        if ($optionBtn.dataset.price) {
            updatePrice(parseInt($optionBtn.dataset.price));
        }
    };

    const handleOptionButtonClick = ($optionBtn) => {
        if (hasClass($optionBtn, 'is-active') || hasClass($optionBtn, 'is-disabled')) {
            return;
        }
        activateOption($optionBtn);
    };

    const createOptionSelectors = (activeOptionValue) => {
        if (!optionsData.length || !$variantSelect) {
            return false;
        }
        if (optionsData[0][2] === false) {
            return false;
        }
        const $optionSelector = document.createElement('div');
        addClass($optionSelector, 'main-option options-as-selector');
        let $activeOptionBtn;
        const variantsData = {};
        for (let i=0, len=optionsData.length; i<len; i++) {
            const [optionType, optionTypeLabel, asSelector, options] =
                optionsData[i];
            if (options && asSelector) {
                // подтягиваем цены, если это единственная опция и у подтоваров разные цены
                if (optionsData.length === 1) {
                    const $$options = $variantSelect.querySelectorAll('option');
                    $$options.forEach(($option, index) => {
                        const options = $option.dataset.options;
                        variantsData[options] = {
                            'value': $option.value,
                        };
                        if ($option.dataset.price) {
                            variantsData[options]['price'] = $option.dataset.price;
                        }
                    });
                }
                for (const key in options) {
                    // ключ key идет в виде 'k{ID} или x{ID}' (для сохранения порядка при JSON.parse)
                    // поэтому нужно удалять это 'k/x' в начале key
                    const id = key.substring(1);
                    const isDisabled = key.charAt(0) === 'x' ? true : false;
                    const optionDisplay = options[key][1]
                    const $optionBtn = document.createElement('button');
                    $optionBtn.dataset.option = id;
                    addClass($optionBtn, 'item');
                    $optionBtn.setAttribute('title',
                        optionTypeLabel.charAt(0).toUpperCase() + optionTypeLabel.substring(1)
                        + ': ' + optionDisplay);
                    if (isDisabled) {
                        addClass($optionBtn, 'is-disabled');
                        $optionBtn.setAttribute('title',
                            $optionBtn.getAttribute('title') + ' (нет в наличии)');
                    }
                    $optionBtn.innerHTML = optionDisplay;
                    const variantData = variantsData[id];
                    if (variantData) {
                        $optionBtn.setAttribute('value', variantData['value']);
                        if (variantData['price']) {
                            $optionBtn.dataset.price = variantData['price'];
                        }
                    }
                    $optionSelector.appendChild($optionBtn);
                    if (!$activeOptionBtn || options[key][0] === activeOptionValue) {
                        $activeOptionBtn = $optionBtn;
                    }
                }
            }
        }
        const $elToInsertBefore = $filteredSelect || $variantSelect;
        $elToInsertBefore.parentNode.insertBefore($optionSelector, $elToInsertBefore);
        $optionSelector.addEventListener('click', (e) => {
            const handlers = [
                ['button.item', handleOptionButtonClick]
            ];
            dispatchEvents(e, handlers);
        });
        if (optionsData.length > 1) {
            createFilteredVariantSelect();
        }
        activateOption($activeOptionBtn, true);
        hideVariantSelect();
        return true;
    };

    const initVariants = () => {
        if (!$variants) {
          return false;
        }
        $variantSelect = $variants.querySelector('select');
        if (!$variantSelect) {
          return false;
        }
        let hashOptionValue;
        if (window.location.hash) {
            hashOptionValue = window.location.hash.substring(1);
        }
        let optionSelector;
        optionsData = JSON.parse($variantSelect.dataset.options);
        if (optionsData) {
            optionSelector = createOptionSelectors(hashOptionValue);
        }
        if (!optionSelector) {
            $variantSelect.addEventListener('change', () => {
                if (!$variantSelect.validity.valueMissing) {
                    if (hasClass($variantSelect, 'is-awaiting-choose')) {
                        removeClass($filteredSelect, 'is-awaiting-choose');
                        doFormSubmit();
                    };
                }
                SEC.gallery.scrollSliderToVariant($variantSelect.value);
            });
        }
    };

    const checkFormValidity = () => {
        let $invalidSelect;
        if ($filteredSelect && $filteredSelect.validity.valueMissing) {
            $invalidSelect = $filteredSelect;
        } else if ($variantSelect && $variantSelect.validity.valueMissing) {
            $invalidSelect = $variantSelect;
        }

        if ($invalidSelect) {
            addClass($invalidSelect, 'is-awaiting-choose');
            anime({
                targets: $invalidSelect,
                scale: [1, 1.25, 1],
                duration: 400,
                easing: 'easeOutCubic',
                complete: () => {
                    $invalidSelect.focus();    
                }
            });
            return false;
        }
        return true;
    };

    const doFormSubmit = () => {
        if (!$form) {
            return false;
        }
        const $btn = $form.querySelector('button[type=submit]');

        const formData = new FormData();
        let variantId;
        if ($variantSelect) {
            variantId = $variantSelect.value;
        } else {
            const $variant = $form.querySelector('input[name=variant]');
            if ($variant) {
                variantId = $variant.value;
            }
        }
        if (variantId) {
            formData.set('variant', variantId);
            formData.set('quantity', 1);
            axios
                .post($form.getAttribute('action'), formData)
                .then((response) => {
                    if (response.status === 200) {
                        removeClass($btn, 'in-process');
                        SEC.unfreezeButton($btn);
                        updateBtn(true);
                        if (response.data['cart_message']) {
                            SEC.updateCartMessage(response.data['cart_message']);
                        };
                    }
                });
                if ($btn) {
                    SEC.unfreezeButton($btn);
                    addClass($btn, 'in-process');
                }
        } else {
            $form.submit();
        } 
    };

    const initAddToCart = () => {
        if (!$form) {
          return false;
        }
        const $btn = $form.querySelector('button[type=submit]');
        if (!$btn) {
            return;
        }
        $btn.addEventListener('click', (e) => {
            e.preventDefault();
            if (hasClass($btn, 'in-process')) {
                return;
            }
            SEC.freezeButton($btn);
            if (checkFormValidity()) {
                doFormSubmit();
            }
        });
    };

    const init = () => {
        if (SEC.page.indexOf('product') === -1) {
            return false;
        }

        if (SEC.$shopActions) {
            $form = SEC.$shopActions.querySelector('.js-add-to-cart-form');
            $variants = SEC.$shopActions.querySelector('.js-variants');
        }
      
        initAddToCart();
        initVariants();
    };

    return {
        init
    };    
})();

document.addEventListener('DOMContentLoaded', function () {
    SEC.$shopActions = document.querySelector('.js-product-shop-actions');

    SEC.product.init();
    SEC.gallery.init();
    SEC.options.init();
});
