let SEC = SEC || {};

SEC.cart = (function () {
  let $cRows;
  let $totals;

  let init;
  let initVoucherForm;

  let getProductBrand;
  let getProductCode;
  let getProductPrice;
  let getProductSlug;
  let getProductVariant;
  let getRowQuantity;
  let initRowRemovers;

  let createProductDataForGa;
  let recordGaCartView;
  let recordGaProductRemove;

  let is_gaRemovingTracked;

  initVoucherForm = () => {
    if (!$totals) {
      return false;
    }

    const $voucherForm = $totals.querySelector('.js-voucher-form');
    if (!$voucherForm || hasClass($voucherForm, 'cart-totals__voucher-form--with-errors')) {
      return false;
    }

    const createAnchor = () => {
      const $cAnchor = document.createElement('div');
      addClass($cAnchor, 'cart-totals__voucher');
      const $anchor = document.createElement('a');
      $anchor.innerText = 'Есть промокод?';
      $anchor.setAttribute('href', 'javascript:void(0);');
      addClass($anchor, 'anchor perm');
      $cAnchor.appendChild($anchor);
      $totals.appendChild($cAnchor);
      $anchor.addEventListener('click', () => {
        showVoucherForm($cAnchor);
      });
    };

    const showVoucherForm = ($cAnchor) => {
      removeClass($voucherForm, 'hidden');
      $cAnchor.parentNode.removeChild($cAnchor);
      const $voucher = $voucherForm.querySelector('input[name=voucher]');
      if ($voucher) {
        $voucher.focus();
      }
    };

    addClass($voucherForm, 'hidden');

    createAnchor();
  };

  getProductBrand = ($row) => {
    return $row.dataset.productBrand;
  };

  getProductCode = ($row) => {
    return $row.dataset.productCode;
  };

  getProductPrice = ($row) => {
    const $price = $row.querySelector('.js-pcs-price');
    if (!$price) {
      return false;
    }

    return $price.dataset.price;
  };

  getProductSlug = ($row) => {
    const $pTitleLink = $row.querySelector('.js-product-title a');
    if (!$pTitleLink) {
      return false;
    }

    return $pTitleLink.getAttribute('href').split('/').reverse()[1];
  };

  getProductVariant = ($row) => {
    const $variant = $row.querySelector('.js-variant');

    if (!$variant) {
      return null;
    }

    return $variant.innerText;
  };

  getRowQuantity = ($row) => {
    const $q = $row.querySelector('.js-quantity-input');
    if (!$q) {
      return null;
    }
    return $q.value;
  };

  createProductDataForGa = ($row) => {
    const productData = {
      'name': getProductSlug($row),
      'id': getProductCode($row),
      'brand': getProductBrand($row),
      'price': getProductPrice($row),
      'quantity': getRowQuantity($row),
    };

    const variant = getProductVariant($row);
    if (variant) {
      productData['variant'] = variant;
    }

    return productData;
  };

  recordGaCartView = () => {
    let productsData = [];

    const $rows = $cRows.querySelectorAll('.js-row:not(.out-of-stock)');
    if (!$rows) {
      return false;
    }

    let pData;
    for (let i=0, len=$rows.length; i<len; i++) {
      pData = createProductDataForGa($rows[i]);
      if (pData['quantity']) {
        productsData.push(pData);
      } else {
        // если у строки нет поля количества, значит или товара нет в наличии (такие строки уже отфильтрованы),
        // или это смазка со скидкой, которая в корзине уже есть за полную цену,
        // тогда суммируем их количество и усредняем цену
        for (let j=0, len2=productsData.length; j<len2; j++) {
          if (pData['id'] === productsData[j]['id']) {
            productsData[j]['quantity']++;
            productsData[j]['price'] = parseInt((parseInt(productsData[j]['price']) + parseInt(pData['price'])) / parseInt(productsData[j]['quantity']));
          }
        }
      }
    }

    window.dataLayer.push({
      'ecommerce': {
        'checkout': {
          'actionField': {'step': 1},
          'products': productsData
        }
      },
      'event': 'gtm-ee-event',
      'gtm-ee-event-category': 'Enhanced Ecommerce',
      'gtm-ee-event-action': 'Checkout Step 1',
      'gtm-ee-event-non-interaction': 'False',
    });
  };

  recordGaProductRemove = ($row) => {
    if (!$row) {
      return false;
    }

    const productData = createProductDataForGa($row);

    window.dataLayer.push({
      'ecommerce': {
        'remove': {
          'products': [
            productData
          ]
        }
      },
      'event': 'gtm-ee-event',
      'gtm-ee-event-category': 'Enhanced Ecommerce',
      'gtm-ee-event-action': 'Removing a Product from a Cart',
      'gtm-ee-event-non-interaction': 'False',

      'eventCallback': () => {
        is_gaRemovingTracked = true;
        const $remove = $row.querySelector('.js-remove');
        $remove.setAttribute('type', 'hidden'); // заменяем тип input с submit на hidden, чтобы пост формы через form.submit приводил к удалению
        $row.querySelector('form').submit();
      }
    });
  };

  initRowRemovers = () => {
    if (!$cRows) {
      return false;
    }
    const $removeIcons = $cRows.querySelectorAll('.js-remove');

    if (!$removeIcons) {
      return false;
    }

    $removeIcons.forEach(el => el.addEventListener('click', (e) => {
      e.preventDefault();

      const $remove = e.target;

      const $form = $remove.closest('form');
      const $row = $form.closest('.js-row');

      recordGaProductRemove($row);

      setTimeout(() => {
        if (!is_gaRemovingTracked) {
          $remove.setAttribute('type', 'hidden'); // заменяем тип input с submit на hidden, чтобы пост формы через form.submit приводил к удалению
          $form.submit();
        }
      }, 600);
    }));
  };

  init = () => {
    if (SEC.page.indexOf('cart') === -1) {
      return false;
    }

    $cRows = document.querySelector('.js-rows');
    $totals = document.querySelector('.js-totals');

    initVoucherForm();
    initRowRemovers();

    recordGaCartView();
  };

  return {
    init
  };

})();

document.addEventListener('DOMContentLoaded', function () {
  SEC.cart.init();
});
