let SEC = SEC || {};

SEC.checkoutSuccess = (function () {
  let init;

  let bindPayButtonClick;
  let markOrderTracked;
  let recordGaCheckoutLastStep;
  let recordGaPurchase;

  let recordAdwordsConversion;

  recordGaCheckoutLastStep = () => {
    const step = 7;
    window.dataLayer.push(SEC.checkout.prepareGaForCheckoutStep(step));
  };

  markOrderTracked = () => {
    // noinspection JSUnresolvedFunction
    axios
      .post(window.location.pathname.replace('checkout-success', 'mark-as-ga-tracked'))
      .then((response) => {
        if (response.data['result'] === 'success') {
        }
      })
  };

  bindPayButtonClick = () => {
    const $payBtn = document.querySelector('.js-pay-btn');
    if (!$payBtn) {
      return false;
    }

    $payBtn.addEventListener('click', () => {
      let logEventName = '#checkout-success__clickPayButton';
      if (typeof secOrderData !== 'undefined') {
        logEventName += '::' + secOrderData['id'];
      }
      SEC.clickyLog(logEventName);
    });
  };

  recordGaPurchase = () => {
    if (typeof secOrderData === 'undefined') {
      return false;
    }

    const gaPurchaseObject = {
      'ecommerce': {
        'purchase': {
          'actionField': secOrderData
        }
      },
      'event': 'gtm-ee-event',
      'gtm-ee-event-category': 'Enhanced Ecommerce',
      'gtm-ee-event-action': 'Purchase',
      'gtm-ee-event-non-interaction': 'False',

      'eventCallback': () => {
        markOrderTracked();
      }

    };

    if (typeof secOrderProducts !== 'undefined') {
      gaPurchaseObject['ecommerce']['purchase']['products'] = secOrderProducts;
    }

    window.dataLayer.push(gaPurchaseObject);
  };

  recordAdwordsConversion = () => {
    if (typeof secOrderData === 'undefined') {
      return false;
    }

    if ('id' in secOrderData) {
      window.dataLayer.push({'gtm-order-id': secOrderData['id']});
    }

    if ('revenue' in secOrderData) {
      window.dataLayer.push({'gtm-order-amount': secOrderData['revenue']});
    }
  };

  init = () => {
    if (SEC.page.indexOf('checkout-success') === -1) {
      return false;
    }
    recordGaCheckoutLastStep();
    recordGaPurchase();
    recordAdwordsConversion();
    bindPayButtonClick();
  };

  return {
    init
  };
})();

document.addEventListener('DOMContentLoaded', function () {
  SEC.checkoutSuccess.init();
});
