let SEC = SEC || {};

SEC.about = (function () {
  const SHIPPING_CITY_FORM_PATH = '/about/delivery/';

  let $deliveryInfo = document.querySelector('.delivery-info');
  let $cityForm = document.querySelector('.js-city-form');

  let init;
  let initAutoSelect;
  let updateDeliveryInfo;

  updateDeliveryInfo = (city, content) => {
    const $city = $deliveryInfo.querySelector('.js-city');
    const $methods = $deliveryInfo.querySelector('.js-methods');
    if (!content || !$city || !$methods) {
      return false;
    }

    $city.innerText = city;
    $methods.innerHTML = content;

    SEC.initCollapses();
  };

  initAutoSelect = () => {
    if (!$cityForm) {
      return false;
    }

    const $submit = $cityForm.querySelector('input[type=submit]');
    if (!$submit) {
      return false;
    }

    const $citySelect = $cityForm.querySelector('.js-city-select');

    $citySelect.addEventListener('change', () => {
      const selectedCity = $citySelect.options[$citySelect.selectedIndex].text;
      const selectedCityVal = $citySelect.value;
      if (selectedCityVal == 0) {
        return false;
      }
      const formData = new FormData();
      formData.set('city', selectedCityVal);
      axios
        .post(SHIPPING_CITY_FORM_PATH, formData)
        .then((response) => {
          updateDeliveryInfo(selectedCity, response.data['delivery_content']);
        });
      if (typeof cityNames !== 'undefined') {
        if (selectedCityVal && parseInt(selectedCityVal) in cityNames) {
          const newPath = SHIPPING_CITY_FORM_PATH + cityNames[parseInt(selectedCityVal)] + '/';
          if (location.search.indexOf('nocity') !== -1) {
            document.location.assign(newPath);
          } else {
            window.history.replaceState('Object', '', newPath);
          }
        }
      }
    });

    addClass($submit, 'hidden');
  };

  init = () => {
    if (SEC.page.indexOf('about') === -1) {
      return false;
    }

    initAutoSelect();
  };

  return {
    init
  };

})();

document.addEventListener('DOMContentLoaded', function () {
  SEC.about.init();
});

