'use strict';

let SEC = (function () {
  const RECAPTCHA_KEY = '6LeousQUAAAAADQtUZnssVNRTESD1DjjzG3Fd1ZF';

  let init;
  let initAxios;
  let initCollapses;
  let initEnquire;
  let initHatSearchIcon;
  let initMainMenu;
  let initMenus;
  let initPhoneIcon;
  let initTitles;
  let initSpecialMessage;
  let plugRecaptchaScript;
  let createSubscriptionForm;
  let loadFooterMenu;
  let loadMenuBrands;

  let hideMenu;
  let updateCartMessage;

  let bindGaForBrandsDropdown;
  let trackGaBrandClick;

  let eqWidth;
  let menuEventsBound;

  let disableForm;
  let enableForm;
  let foldContent;
  let freezeButton;
  let unfreezeButton;
  let removeLoaderFrom;
  let showLoaderAfter;
  let showLoaderAbove;
  let scrollTo;
  let equalizeHeight;

  let clickyLog;

  let $menu;
  let $shade;

  scrollTo = ($el, callback, offset) => {
    if (typeof $el === 'string') {
      $el = document.querySelector($el);
    }
    if (!$el) {
      return false;
    }
    offset = offset || 0;
    const aniScroll = anime({
      targets: 'html, body',
      scrollTop: $el.offsetTop - 100 + offset,
      easing: 'easeOutCubic',
      duration: 500
    });

    if (callback && typeof callback === 'function') {
      aniScroll.complete = () => {
        callback();
      };
    }
  };

  disableForm = ($form) => {
    const $els = $form.elements;
    if (!$els) {
      return false;
    }
    for (let i=0, l=$els.length; i < l; ++i) {
      $els[i].setAttribute('disabled', 'disabled');
    }
    addClass($form, 'is-disabled');
  };

  enableForm = ($form) => {
    const $els = $form.elements;
    if (!$els) {
      return false;
    }
    for (let i=0, l=$els.length; i < l; ++i) {
      $els[i].removeAttribute('disabled');
    }
    removeClass($form, 'is-disabled');
  };

  freezeButton = ($el, timeout) => {
    $el.setAttribute('disabled', 'disabled');
    addClass($el, 'btn--frozen');
    if (!timeout) {
      return;
    }
    setTimeout(() => {
      unfreezeButton($el);
    }, timeout);
  };

  unfreezeButton = ($el) => {
    $el.removeAttribute('disabled');
    removeClass($el, 'btn--frozen');
  };

  initAxios = () => {
    if (typeof axios === 'undefined') {
      return false;
    }
    axios.defaults.xsrfCookieName = 'csrftoken';
    axios.defaults.xsrfHeaderName = 'X-CSRFToken';
    axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest';
  };

  initCollapses = () => {
    const $collapses = document.querySelectorAll('.collapsible');
    if (!$collapses) {
      return false;
    }

    for (let i=0, len=$collapses.length; i<len; i++) {
      let $cLink = $collapses[i];
      if (!$cLink.dataset.collapseFor) {
        continue;
      }

      let $c = document.querySelectorAll('.js-' + $cLink.dataset.collapseFor);
      if (!$c) {
        continue;
      }

      addClass($c, 'hidden');

      addClass($cLink, 'is-collapsed');
      $cLink.innerHTML = '<a href="javascript:void 0;" class="anchor">' + $cLink.innerHTML + '</a>';

      $cLink.addEventListener('click', (e) => {
        if (hasClass($cLink, 'is-collapsed')) {
          removeClass($cLink, 'is-collapsed');
          removeClass($c, 'hidden');
        } else {
          addClass($cLink, 'is-collapsed');
          addClass($c, 'hidden');
        }
      });
    }
  };

  initEnquire = () => {
    SEC.eqWidth = 'small';
    window.enquire
      .register('screen and (min-width: 360px)', () => {
        SEC.eqWidth = 'small-plus';
      })
      .register('screen and (min-width: 720px)', () => {
        SEC.eqWidth = 'medium-plus';
      })
      .register('screen and (min-width: 1024px)', () => {
        SEC.eqWidth = 'large';
      })
      .register('screen and (min-width: 1050px)', () => {
        SEC.eqWidth = 'large-plus';
      });
  };

  initHatSearchIcon = () => {
    const $searchIcon = document.querySelector('.js-search-icon');
    if (!$searchIcon) {
      return false;
    }

    $searchIcon.addEventListener('click', (e) => {
      e.preventDefault();

      const $form = document.querySelector('.js-search-form');

      if (hasClass($form, 'isolated')) {
        if ($form.style.display === 'none') {
          $form.style.display = '';
        } else {
          $form.style.display = 'none';
        }
      } else {
        const $hat = document.querySelector('.hat');
        addClass($form, 'isolated');
        $hat.parentNode.insertBefore($form, $hat.nextSibling);
        $form.querySelector('input[type=text]').focus();
      }
    });
  };

  initPhoneIcon = () => {
    const $phone = document.querySelector('.js-phone');
    const $phoneIcon = document.querySelector('.js-phone-icon');
    if (!$phone || !$phoneIcon) {
      return false;
    }

    const $tip = document.createElement('span');
    addClass($tip, 'top__phone__tip')
    $tip.innerHTML = '← Нажмите, чтобы позвонить';
    $phone.appendChild($tip);

    const $closeIcon = document.createElement('span');
    addClass($closeIcon, 'top__icon icon-close');
    $closeIcon.innerHTML = '<span class="icon"></span>'
    $phone.appendChild($closeIcon);

    $closeIcon.addEventListener('click', (e) => {
      removeClass($phone, 'activated');
    });

    $phoneIcon.addEventListener('click', (e) => {
      e.preventDefault();
      addClass($phone, 'activated');
    });
  };

  initTitles = () => {
    const $el = document.querySelectorAll('.dfn, .c-product__f-icon');

    for (let i = 0; i < $el.length; i++) {
      setAttributes($el[i], {
        'data-balloon': $el[i].getAttribute('title'),
        'data-balloon-pos': 'up',
        'title': ''
      });
      if (hasClass($el[i], 'dfn')) {
        $el[i].setAttribute('data-balloon-length', 'large');
      }
    }
  };

  initSpecialMessage = () => {
    const $els = document.querySelectorAll('.l-special-message');
    for (let i = 0; i < $els.length; i++) {
      const $sm = $els[i];
      const smName = $sm.dataset.smname;
      const $close = $sm.querySelector('.js-sm-close');
      if (!$close) {
        continue;
      }

      $close.addEventListener('click', (e) => {
        e.preventDefault();
        const aniSlide = anime({
          targets: $sm,
          height: 0,
          duration: 350,
          easing: 'easeOutCubic'
        });
        aniSlide.complete = () => {
          addClass($sm, 'hidden');
          if (smName) {
            document.cookie = 'hidden_sm=' + smName + '; path=/; max-age=2592000';
          }
        }
      });
    }

  };

  foldContent = (selector, minHeight) => {
    const $els = document.querySelectorAll(selector);

    if (!$els) {
      return false;
    }

    const handleUnfoldClick = (e) => {
      const $icon = e.target;
      if (!$icon) {
        return false;
      }

      const $el = $icon.closest(selector);
      if (!$el) {
        return false;
      }

      aniFadeOut($icon);

      const aniSlide = anime({
        targets: $el,
        height: $el.scrollHeight,
        duration: 500,
        easing: 'easeOutCubic'
      });

      aniSlide.complete = () => {
        removeClass($el, 'is-folded');
        $el.removeAttribute('style');
      };

      const eName = $el.dataset.eventName;
      if (eName) {
        SEC.clickyLog('#unfold' + eName);
      }
    };

    for (let i=0, len=$els.length; i<len; i++) {
      if (minHeight) {
        let elHeight = $els[i].offsetHeight;
        if (elHeight < minHeight) {
          continue;
        }
      }

      addClass($els[i], 'is-folded');

      const $arr = document.createElement('span');
      addClass($arr, 'unfold-icon');
      $els[i].append($arr);
      $arr.addEventListener('click', handleUnfoldClick)
    }
  };

  showLoaderAbove = ($el, isTransparent) => {
    if (!$el) {
      return false;
    }
    $el.style.position = 'relative';
    const $fog = document.createElement('div');
    const fogClass = isTransparent ? 'fog' : 'fog-white';
    addClass($fog, fogClass);
    $fog.innerHTML = '<div class="loader"></div>';
    $el.appendChild($fog);
  };

  showLoaderAfter = ($el) => {
    $el.insertAdjacentHTML('afterend', '<div class="loader"></div>');
  };

  removeLoaderFrom = ($el) => {
    const $fog = $el.querySelector('.fog, .fog-white');
    if ($fog) {
      $fog.parentNode.removeChild($fog);
      return true;
    }
    const $loader = $el.querySelector('.loader');
    if ($loader) {
      $loader.parentNode.removeChild($loader);
      return true;
    }
    return false;
  };

  plugRecaptchaScript = () => {
    const $head = document.getElementsByTagName('head')[0];
    const $script = document.createElement('script');
    $script.type = 'text/javascript';
    $script.src = 'https://www.google.com/recaptcha/api.js?render=' + RECAPTCHA_KEY;
    $head.appendChild($script);
  };

  createSubscriptionForm = () => {
    const $footer = document.querySelector('.js-footer');
    if (!$footer || hasClass($footer, 'no-subscription')) {
      return false;
    }
    plugRecaptchaScript();

    axios
      .get('/get-subscription-form/?next=' + window.location.pathname + window.location.search)
      .then((response) => {
        $footer.insertAdjacentHTML('afterbegin', response.data);
        const $form = $footer.querySelector('.subscription-form');
        if (!$form) {
          return false;
        }
        $form.addEventListener('submit', (e) => {
          e.preventDefault();
          // disableForm($form);
          addClass($form, 'is-disabled');
          showLoaderAbove($form, true);
          grecaptcha.ready(() => {
            grecaptcha.execute(RECAPTCHA_KEY, {action: 'subscribe'})
              .then(token => {
                const $inp=document.createElement('input');
                $inp.setAttribute('type', 'hidden');
                $inp.setAttribute('name', 'g-recaptcha-response');
                $inp.setAttribute('value', token);
                $form.appendChild($inp);

                const formData = new FormData($form);
                axios
                  .post($form.getAttribute('action'), formData)
                  .then((response) => {
                    removeLoaderFrom($form);
                    removeClass($form, 'is-disabled');
                    // enableForm($form);
                    const $div = document.createElement('div');
                    addClass($div, 'text-block centered-fully');
                    $div.setAttribute('style', 'height:' + $form.offsetHeight + 'px');
                    $div.innerHTML = '<div>' + response.data['msg'] + '</div>';
                    $form.parentNode.replaceChild($div, $form);
                  });

//                $form.submit();
              })
            });
        });
//        window.__recaptchaCallback=function(){if(window.grecaptcha){grecaptcha.execute('{{ RECAPTCHA_KEY }}',{action:'subscribe'}).then(function(token){var inp=document.createElement('input');inp.setAttribute('type','hidden');inp.setAttribute('name','g-recaptcha-response');inp.setAttribute('value',token);document.getElementById('subscribe').appendChild(inp)})}}
      })
  };

  loadFooterMenu = () => {
    const $footer = document.querySelector('.js-footer');
    if (!$footer) {
      return false;
    }

    if (SEC.page.indexOf('checkout') !== -1 || SEC.page.indexOf('order-pay') !== -1) {
      addClass($footer, 'no-menu');
      return false;
    }

    axios
      .get('/get-footer-menu/')
      .then((response) => {
        const $sForm = $footer.querySelector('.subscription-form');
        if ($sForm) {
          $sForm.insertAdjacentHTML('afterend', response.data);
        } else {
          $footer.insertAdjacentHTML('afterbegin', response.data);
        }
      })
  };

  hideMenu = () => {
    const $active = $menu.querySelectorAll('.menu__item.is-active');
    if ($active) {
      for (let i=0, len=$active.length; i<len; i++) {
        removeClass($active[i], 'is-active');
      }
    }
    addClass($shade, 'hidden');
    removeClass($menu, 'is-activated');
  };

  initMainMenu = (initial) => {
    if (initial) {
      // формируем колонки из пунктов меню
      const $$submenus = $menu.querySelectorAll('.menu__submenu-l1');
      for (let i=0, len=$$submenus.length; i<len; i++) {
        const $$submenuItems = $$submenus[i].querySelectorAll('.menu__item-l2');
        if (!$$submenuItems) {
          continue;
        }
        let $column;
        let prevItemColumn;
        let columnsNumber = 0;
        for (let j=0, len=$$submenuItems.length; j<len; j++) {
          const $item = $$submenuItems[j];
          const itemColumn = $item.dataset.column;
          if (itemColumn && itemColumn != prevItemColumn) {
            columnsNumber++;
            $column = document.createElement('div');
            addClass($column, 'menu__column');
            $item.parentNode.appendChild($column);
          }
          if ($column) {
            $column.appendChild($item);
          }
          prevItemColumn = itemColumn;
        }
        if (columnsNumber > 1) {
          addClass($$submenus[i], 'menu__submenu-' + columnsNumber + '-columns');
        }
      }
    }

    const shadeLayout = () => {
      if (!$shade) {
        $shade = document.createElement('div');
        addClass($shade, 'shade');
        document.body.appendChild($shade);
        $shade.addEventListener('click', hideMenu);
      } else {
        removeClass($shade, 'hidden');
      }
      const shadeTop = $menu.offsetTop + $menu.offsetHeight;
      $shade.style.top = shadeTop + 'px';
      const pageHeight = Math.max(
        document.body.scrollHeight, document.body.offsetHeight, document.body.clientHeight,
        document.documentElement.clientHeight, document.documentElement.scrollHeight, document.documentElement.offsetHeight);
      $shade.style.height = (pageHeight - shadeTop) + 'px';
    };

    const handleL3ItemClick = ($link) => {
      hideMenu();
      const url = $link.getAttribute('href');
      window.location = url;
    };

    const handleL2ItemClick = ($link) => {
      const $menuItem = $link.closest('.menu__item');
      if (hasClass($menuItem, 'is-active')) {
        removeClass($menuItem, 'is-active');
        return;
      } else {
        const $submenu = $menuItem.closest('.menu__submenu');
        if ($submenu) {
          removeClass($submenu.querySelectorAll('.menu__item.is-active'), 'is-active');
        }
        addClass($menuItem, 'is-active');
      }
    };

    const handleRootItemClick = ($link) => {
      const $rootItem = $link.closest('.menu__item-l1');
      if (!$rootItem) {
        return false;
      }
      if (hasClass($rootItem, 'is-active')) {
        hideMenu();
        return false;
      }
      shadeLayout();
      const $activeRootItems = $menu.querySelectorAll('.menu__item-l1.is-active');
      removeClass($activeRootItems, 'is-active');

      addClass($menu, 'is-activated');
      addClass($rootItem, 'is-active');
    };

    const handleMenuClicks = (e) => {
      const handlers = [
        ['.menu__item-l3 a, .menu__item-brands a, .menu__item-sale a, .menu__item-l2:not(.menu__item-w-children) a', handleL3ItemClick],
        ['.menu__item-l2 a', handleL2ItemClick],
        ['.menu__item-l1 a', handleRootItemClick]
      ];
      dispatchEvents(e, handlers);
    };

    if (!menuEventsBound && (SEC.eqWidth === 'small' || SEC.eqWidth === 'small-plus')) {
      $menu.addEventListener('click', handleMenuClicks);
      menuEventsBound = true;
    }
  };

  loadMenuBrands = () => {
    const $brands = document.querySelector('.js-menu-brands');
    if (!$brands) {
      return false;
    }

    axios
      .get('/get-main-menu-brands/')
      .then((response) => {
        $brands.insertAdjacentHTML('beforeend', response.data);
        removeClass($brands, 'menu__item-static');
        bindGaForBrandsDropdown();
      });
  };

  initMenus = () => {
    $menu = document.querySelector('.js-main-menu');
    if ($menu) {
      initMainMenu(true);

      if (typeof window.enquire !== 'undefined') {
        window.enquire.register('screen and (min-width: 720px)', {
          match: () => {
            initEnquire();
            hideMenu();
          },
          unmatch: () => {
            initEnquire();
            initMainMenu();
          },
          deferSetup: true
        });
      }

      axios
        .get('/get-main-menu/')
        .then((response) => {
          // если меню открыто в момент загрузки полной версии, фиксируем все активные пункты
          // и после обновления делаем их активными
          let $activeItems;
          if (hasClass($menu, 'is-activated')) {
            $activeItems = $menu.querySelectorAll('.menu__item.is-active');
          }
          $menu.innerHTML = response.data;
          if ($activeItems) {
            for (let i=0, len=$activeItems.length; i<len; i++) {
              const $item = $menu.querySelector('[data-cid="' + $activeItems[i].dataset.cid + '"]');
              addClass($item, 'is-active');
            }
          }
          initMainMenu(true);
          loadMenuBrands();
        });
    }
  };

  updateCartMessage = (message) => {
    if (typeof message == 'undefined') {
      return false;
    }
    const $cartMessage = document.querySelector('.js-cart-message');
    if ($cartMessage) {
      $cartMessage.innerText = message;
    }
    const $cartCount = document.querySelector('.js-cart-count');
    if ($cartCount) {
      $cartCount.innerText = parseInt(message);
    }
  };

  equalizeHeight = (elements, selectors) => {
    // делает одинаковой высоту элементов, соответствующих selectors
    // elements: массив с родительскими элементами
    // selectors: массив селекторов, по которым будем искать подэлементы
    // селектор также может быть массивом [selector, rows],
    // где rows -- количество строк текста в элементе, больше которого будем сворачивать
    // если rows === -1, то селектор не обрабатываем, и он просто будет служить previousSelector

    if (!Array.isArray(selectors)) {
      return;
    }

    const getNRowsElHeight = ($el, rows) => {
      const $clone = $el.cloneNode(true);
      $clone.innerHTML = 'X' + Array(rows).join('<br>X');
      $clone.style.position = 'absolute';
      $clone.style.left = '-9999px';
      $el.parentNode.appendChild($clone);
      const height = $clone.offsetHeight;
      $clone.remove();
      return height;
    };

    const equalizeHeightBySelector = (selector, previousSelector, maxRows) => {
      // в previousSelector передаем селектор для предыдущего элемента,
      // чтобы если у элемента отсутствует подэлемент, соответствующий selector,
      // компенсировать это, добавляя height предыдущему
      const $$elements = elements.map(($el) => {
        return $el.querySelector(selector);
      });
      // проверяем, что в $$elements есть непустые элементы
      const nonEmptyExists = $$elements.some((el) => {
        return el !== null;
      });
      if (!nonEmptyExists) {
        return false;
      }
      let $elToClone;
      // собираем высоту каждого элемента в $$elements в массив
      const heights = $$elements.map(($el) => {
        if ($el) {
          $elToClone = $el;
          $el.style.height = '';
          return $el.clientHeight;
        } else {
          return 0;
        }
      });
      // максимальная высота среди всех элементов
      let maxElementsHeight = Math.max.apply(Math, heights);
      let maxHeight;
      if (maxRows && $elToClone) {
        maxHeight = getNRowsElHeight($elToClone, maxRows);
      }

      if (maxHeight < maxElementsHeight) {
        maxElementsHeight = maxHeight;
      }

      $$elements.forEach(($el, idx) => {
        if ($el) {
          if (maxHeight && heights[idx] > maxHeight) {
            addClass($el, 'is-folded');
            $el.style.maxHeight = maxHeight + 'px';
          } else if (heights[idx] < maxElementsHeight) {
            $el.style.height = maxElementsHeight + 'px';
          }
        } else {
          // если элемента нет, пытаемся взять предыдущий (previousSelector)
          // и увеличить высоту ему
          const $prevElement = elements[idx].querySelector(previousSelector);
          if ($prevElement) {
            if (hasClass($prevElement, 'is-folded')) {
              // если предыдущий элемент был укорочен, убираем .is-folded и смотрим его полную высоту,
              // если она по-прежнему больше (foldedHeight + maxElementsHeight), то возвращаем .is-folded
              // с пересчетом остатка
              let foldedHeight = $prevElement.clientHeight;
              $prevElement.style.maxHeight = '';
              removeClass($prevElement, 'is-folded');
              let realHeight = $prevElement.clientHeight;
              if (realHeight > foldedHeight + maxElementsHeight) {
                addClass($prevElement, 'is-folded');
                $prevElement.style.maxHeight = foldedHeight + maxElementsHeight + 'px';
              } else {
                $prevElement.style.height = foldedHeight + maxElementsHeight + 'px';
              }
            } else {
              $prevElement.style.height = $prevElement.clientHeight + maxElementsHeight + 'px';
            }
          }
        }
      });
    };

    let previousSelector;
    for (let i=0, len=selectors.length; i<len; i++) {
      const selector = selectors[i];
      if (typeof selector === 'string') {
        equalizeHeightBySelector(selector, previousSelector);
        previousSelector = selector;
      } else if (Array.isArray(selector)) {
        previousSelector = selector[0];
        if (selector[1] === -1) {
          continue;
        }
        equalizeHeightBySelector(selector[0], previousSelector, selector[1]);
      }
    };
  }

  trackGaBrandClick = ($link) => {
    if (!$link || $link.tagName.toUpperCase() !== 'A') {
      return false;
    }

    const url = $link.getAttribute('href');
    if (url.indexOf('/brands/') === 0) {
      const brand = url.split('/')[2];
      const logEventName = '#mainMenuBrandClick::' + brand;
      SEC.clickyLog(logEventName);
    }

    setTimeout(() => {
      window.location = url;
    }, 400);
  };

  bindGaForBrandsDropdown = () => {
    const $dropdown = document.querySelector('.js-menu-brands-dd');
    if (!$dropdown) {
      return false;
    }
    $dropdown.addEventListener('click', function(e) {
      const handlers = [
        ['.menu__item a', trackGaBrandClick],
      ];
      dispatchEvents(e, handlers);
    });
  };

  clickyLog = (href, title, type='click') => {
    if (typeof window.clicky === 'undefined') {
      return false;
    }

    if (title) {
      window.clicky.log(href, title, type);
    } else {
      window.clicky.log(href);
    }
  };

  const shade = (openCallback, closeCallback) => {
    const $shade = document.createElement('div');
    addClass($shade, 'layout-shade invisible');
    document.body.appendChild($shade);
    aniFadeIn($shade, openCallback, {duration: 200});
    const removeShade = (e) => {
      $shade.remove();
      document.body.removeEventListener('click', removeShade);
      if (closeCallback) {
        closeCallback();
      }
    }
    $shade.addEventListener('click', removeShade);
  };

  const unShade = (callback) => {
    const $shade = document.querySelector('.layout-shade');
    if (!$shade) {
      return;
    }
    $shade.remove();
    if (callback) {
      callback();
    }
  };

  init = () => {
    SEC.page = document.body.dataset.page;
    SEC.isTouchDevice = isTouchDevice();
    if (SEC.isTouchDevice) {
      addClass(document.body, 'touch-device');
    } else {
      bindGaForBrandsDropdown();
    }

    initAxios();
    initCollapses();
    initEnquire();
    initHatSearchIcon();
    initMenus();
    initPhoneIcon();
    initTitles();
    initSpecialMessage();

    loadFooterMenu();
    createSubscriptionForm();
  };

  return {
    init,
    initCollapses,

    clickyLog,

    showLoaderAbove,
    showLoaderAfter,
    removeLoaderFrom,

//    createOverlay,
    foldContent,
    freezeButton,
    unfreezeButton,
    scrollTo,
    equalizeHeight,

    updateCartMessage,

    shade,
    unShade,

    eqWidth
  };
})();

document.addEventListener('DOMContentLoaded', function () {
  SEC.init();
});
